import React, {useContext, useEffect, useState} from 'react';
import {useApplyMixDiscountCodeQuery} from '@graphql/generated/graphql';
import {useMixOrderStore, usePersistentStore} from 'stores';
import {useRouter} from 'next/router';
import {CartGroupContext} from './CartGroup';

interface ICartMixDiscountProps {}

const CartMixDiscount: React.FC<ICartMixDiscountProps> = () => {
  const router = useRouter();
  const outletFriendlyPath = router.query.friendlyPath as string;

  const {
    storeData,
    cartMixinPromo: {isFoundMixinPromoApplied},
  } = useContext(CartGroupContext);

  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const setMixOrderInput = useMixOrderStore(s => s.setMixOrderInput);
  const deliveryOption = usePersistentStore(s => s.deliveryOption);
  const paymentMethod = useMixOrderStore(s => s.paymentMethod);
  const discountCode = mixOrderInput.discount.code;

  const subtotal = mixOrderInput.stores
    .filter(({checked}) => checked)
    .reduce((acc, store) => {
      return acc + store.menuItems.reduce((acc, item) => acc + item.subtotal * item.count, 0);
    }, 0);

  const [code, setCode] = useState<string>(discountCode);
  const [message, setMessage] = useState<{success: boolean; text: string}>({success: false, text: ''});

  const {refetch: fetchApplyMixDiscountCode} = useApplyMixDiscountCodeQuery({skip: true});
  const handleApplyMixDiscountCode = async () => {
    const {data: res} = await fetchApplyMixDiscountCode({
      storeData,
      discountCode: code,
      subtotal,
      deliveryOption,
      paymentMethod,
    });
    if (res.applyMixDiscountCode) {
      setMessage({
        success: res.applyMixDiscountCode.success,
        text: res.applyMixDiscountCode.message,
      });
      const data = res.applyMixDiscountCode.discounts.map((discount, index) => ({
        code,
        storeFriendlyPath: storeData[index].friendlyPath,
        amount: discount.appliedAmount,
        message: discount.error,
      }));
      setMixOrderInput(outletFriendlyPath, {
        ...mixOrderInput,
        discount: {code, data},
      });
    }
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    setMessage({success: false, text: ''});
  };

  useEffect(() => {
    setMessage({success: false, text: ''});
  }, [subtotal]);

  if (isFoundMixinPromoApplied) return null;

  return (
    <section className="mt-4">
      <p className="sectionHeaderSemibold">Discount Code</p>

      <div className="mt-2">
        <div className="relative">
          <input
            name="discountCode"
            type="text"
            placeholder="Enter discount code here"
            className="appearance-none block w-full px-3 py-2 grayBoxBorder rounded-md focus:outline-none focus:ring-0 focus:border-gray poppins-medium placeholder-gray"
            style={{textTransform: 'uppercase'}}
            value={code}
            onChange={handleChangeCode}
            maxLength={10}
          />
          <button
            className="bg-primary rounded poppins-medium text-white font-sm py-1 px-2 absolute right-3 top-1/2 transform -translate-y-1/2 focus:outline-none"
            onClick={handleApplyMixDiscountCode}
          >
            Apply
          </button>
        </div>
      </div>

      {!!message.text && <p className={`mt-2 poppins-medium font-xs ${message.success ? 'text-success' : 'text-error'}`}>{message.text}</p>}
    </section>
  );
};

export default CartMixDiscount;
