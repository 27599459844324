import React from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {AvailableStatus, OutletStorePageQuery} from '@graphql/generated/graphql';
import useCountryData from '@lib/useCountryData';

interface IOutletStoreMenuItemProps {
  country: string;
  storeAndCategoryActive: boolean;
  itemCount: number;
  item: OutletStorePageQuery['store']['menuSection']['menuCategories'][0]['menuItems'][0];
}

const OutletStoreMenuItem: React.FC<IOutletStoreMenuItemProps> = ({
  country,
  storeAndCategoryActive,
  itemCount,
  item: {id, name, image, description, withPromo, priceWithTax, priceWithPromo, availableStatus},
}) => {
  const router = useRouter();
  const {friendlyPath} = router.query;

  const {currency} = useCountryData(country);
  const availableOverride = storeAndCategoryActive && availableStatus === AvailableStatus.Available;

  const children = (
    <div className="flex flex-row justify-between items-center w-full">
      <div className="relative w-1/4">
        <img src={image ?? '/image-placeholder.jpg'} alt={name} className="w-full object-cover rounded-2xl" />
        {!!itemCount && (
          <div className="absolute rounded-full -top-1 -right-2 w-7 h-7 bg-black flex flex-row items-center justify-center">
            <p className="poppins-semibold font-sm self-center text-white">{itemCount}x</p>
          </div>
        )}
      </div>

      <div className="flex flex-col flex-1 justify-center items-start ml-4">
        {!availableOverride && <p className="closed text-gray uppercase">Unavailable</p>}
        <p className="poppins-semibold">{name}</p>
        {!!description?.trim()?.length && <p className="subTextGray mb-2 line-clamp-2">{description}</p>}

        <div className="flex flex-row justify-between items-center">
          <p className={`mr-3 poppins-semibold whitespace-nowrap ${withPromo && 'line-through subTextGray'}`}>
            {currency} {priceWithTax.toFixed(2)}
          </p>
          {withPromo && (
            <p className="poppins-semibold text-primary whitespace-nowrap">
              {currency} {priceWithPromo.toFixed(2)}
            </p>
          )}
        </div>
      </div>
    </div>
  );

  return availableOverride ? (
    <Link href={`/outlets/${friendlyPath}/menuItem/${id}`}>
      <a className="cursor-pointer py-4 lightGrayBottomBorder">{children}</a>
    </Link>
  ) : (
    <div className="py-4 opacity-50 lightGrayBottomBorder">{children}</div>
  );
};

export default React.memo(OutletStoreMenuItem);
