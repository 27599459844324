import React from 'react';
import {DeliveryOption} from '@graphql/generated/graphql';
import {usePersistentStore} from 'stores';
import {DELIVERY_SELECTIONS} from 'modules';

interface ICartDeliveryOptionsProps {}

const CartDeliveryOptions: React.FC<ICartDeliveryOptionsProps> = () => {
  const deliveryOption = usePersistentStore(s => s.deliveryOption);
  const setDeliveryOption = usePersistentStore(s => s.setDeliveryOption);

  const deliveryTabs = Object.values(DeliveryOption)
    .map(option => DELIVERY_SELECTIONS[option])
    .filter(value => Object.keys(value).length !== 0);

  return (
    <section className="bg-bgColor pt-3 flex flex-row justify-center items-center">
      {deliveryTabs.map(({text, option}) => (
        <div
          key={option}
          className={`w-1/3 text-center poppins-semibold py-0.5 mx-2 cursor-pointer rounded-full transition duration-100 ${
            deliveryOption === option ? 'text-white bg-primary' : 'text-gray grayRoundBorder'
          }`}
          onClick={() => setDeliveryOption(option)}
        >
          {text}
        </div>
      ))}
    </section>
  );
};

export default CartDeliveryOptions;
