import React from 'react';
import {OutletMartMenuItemPageQuery} from '@graphql/generated/graphql';

interface IProductItemHeaderProps {
  menuItem: OutletMartMenuItemPageQuery['menuItem'];
}

const ProductItemHeader: React.FC<IProductItemHeaderProps> = ({
  menuItem: {name, description, withPromo, withMembership, priceWithTax, priceWithPromo, priceWithMembership},
}) => {
  return (
    <header className="bg-white paddingHorizontal py-5 defaultShadow">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col gap-1 flex-1">
          <p className="poppins-semibold font-lg">{name}</p>
          {!!description?.trim()?.length && <p className="subTextGray">{description}</p>}
        </div>
        <div className="flex flex-col justify-between items-end mb-2.5">
          {withMembership ? (
            <>
              <p className="poppins-semibold font-lg ">RM {priceWithMembership.toFixed(2)}</p>
              <p className="poppins-medium font-sm text-primary">Saved RM {(priceWithTax - priceWithMembership).toFixed(2)}</p>
            </>
          ) : (
            <>
              {withPromo && <p className="poppins-semibold font-lg">RM {priceWithPromo.toFixed(2)}</p>}
              <p className={`poppins-semibold font-lg ${withPromo && 'line-through subTextGray'}`}>RM {priceWithTax.toFixed(2)}</p>
              <div className="flex flex-row justify-between items-start self-end">
                <img src="/coox-mart.png" className="w-4 h-4 mr-2" />
                <p className="poppins-semibold text-primary">RM {priceWithMembership.toFixed(2)}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default React.memo(ProductItemHeader);
