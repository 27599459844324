import React from 'react';

interface IMenuItemCountProps {
  count: number;
  handleChange: (count: number) => void;
}

const MenuItemCount: React.FC<IMenuItemCountProps> = ({count, handleChange}) => {
  return (
    <section>
      <div className="w-full flex justify-center text-center mt-6">
        <div className="w-1/3 self-center flex flex-row justify-evenly items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            className="cursor-pointer"
            viewBox="0 0 1024 1024"
            onClick={() => handleChange(Math.max(count - 1, 0))}
          >
            <path
              fill="#ff9906"
              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h368c4.4 0 8 3.6 8 8v48z"
            />
          </svg>
          <div className="poppins-medium font-lg">{count}</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            className="cursor-pointer"
            viewBox="0 0 1024 1024"
            onClick={() => handleChange(Math.max(count + 1, 0))}
          >
            <path
              fill="#ff9906"
              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default MenuItemCount;
