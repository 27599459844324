import React from 'react';
import Image from 'next/image';
import {parseCookies} from 'nookies';
import {DeliveryService, usePreviewLalamoveQuery} from '@graphql/generated/graphql';
import {DELIVER_TO_ADDRESS, DELIVER_TO_LATITUDE, DELIVER_TO_LONGITUDE} from '@lib/helpers/helper';
import PreviewLoader from './PreviewLoader';

interface IPreviewLalamoveProps {
  outletId?: string;
  storeId?: string;
  deliveryService: DeliveryService;
  setDeliveryService: (service: DeliveryService) => void;
  setDeliveryData: (fee: number, metadata: string) => void;
}

const PreviewLalamove: React.FC<IPreviewLalamoveProps> = ({outletId, storeId, deliveryService, setDeliveryService, setDeliveryData}) => {
  const parsedCookies = parseCookies();
  const address = parsedCookies[DELIVER_TO_ADDRESS];
  const lat = +parsedCookies[DELIVER_TO_LATITUDE];
  const long = +parsedCookies[DELIVER_TO_LONGITUDE];

  const {data, loading} = usePreviewLalamoveQuery({
    variables: {
      outletId,
      storeId,
      address,
      lat,
      long,
    },
    fetchPolicy: 'no-cache',
    skip: !address || !lat || !long,
  });

  const isSelected = (serviceType: DeliveryService) => deliveryService === serviceType;

  const handleDeliveryService = (serviceType: DeliveryService, deliveryPrice: number, deliveryMetadata: string) => {
    setDeliveryService(serviceType);
    setDeliveryData(deliveryPrice, deliveryMetadata);
  };

  if (!data || loading) return <PreviewLoader serviceType={DeliveryService.Lalamove} />;

  const {
    previewLalamove: {serviceName, serviceType, deliveryPrice, deliveryMetadata, error},
  } = data;

  return (
    <div
      className={`flex h-20 bg-white py-2 px-4 m-2 rounded-lg items-center w-full shadow-sm cursor-pointer
      ${isSelected(serviceType) && 'orangeBoxBorder orangeShadow'}`}
      onClick={() => {
        !error && handleDeliveryService(serviceType, deliveryPrice, deliveryMetadata);
      }}>
      <div className="flex flex-row items-center justify-between w-full">
        <Image src="/lalamove.png" width={40} height={40} className="bg-transparent" />
        <div className="flex flex-col flex-1 ml-4 items-start">
          <p className="poppins-semibold capitalize">{serviceName}</p>
          {error && <p className="poppins-medium text-error text-xs line-clamp-2 break-words w-full sm:w-24">{error}</p>}
          {deliveryPrice > 0 && <p className="poppins-medium mt-0.5">RM {deliveryPrice.toFixed(2)}</p>}
        </div>
      </div>
    </div>
  );
};

export default PreviewLalamove;
