import {CartOutletType, MartCartOutletType} from 'stores';

const getOutletItemsCount = (outlet: CartOutletType) => {
  if (!outlet?.stores) return 0;
  return outlet.stores.reduce((acc, store) => {
    return acc + store.menuItems.reduce((acc, item) => acc + item.count, 0);
  }, 0);
};

export const getOutletMartItemsCount = (outlet: MartCartOutletType) => {
  if (!outlet?.stores) return 0;
  return outlet.stores.reduce((acc, store) => {
    return acc + store.menuItems.reduce((acc, item) => acc + item.count, 0);
  }, 0);
};

export default getOutletItemsCount;
