import React, {createContext, useMemo} from 'react';
import {
  DeliveryOption,
  DeliveryService,
  GetCartStoresByOutletQuery,
  PaymentMethod,
  useAppMetadataQuery,
  useGetCartStoresByOutletQuery,
} from '@graphql/generated/graphql';
import {useMixOrderStore, usePersistentStore} from 'stores';
import {useCartMixinPromo} from '@lib/index';
import {OutputType} from '@lib/cart/useCartMixinPromo';

interface ICartGroupContextProps {
  outletId: string;
  outletFriendlyPath: string;
  cartMixinPromo: OutputType;
  storesData: GetCartStoresByOutletQuery;
  storeData: {
    friendlyPath: string;
    subtotal: number;
  }[];
  handleCheckout: () => void;
}

export const CartGroupContext = createContext<ICartGroupContextProps>(null);

type StoreOrderAttrType = {
  storeId: string;
  menuItems: {
    id: string;
    menuOptionItemIds: string[];
    remark: string;
  }[];
};

export type CheckoutVariableType = {
  outletId: string;
  deliveryOption: DeliveryOption;
  deliveryService: DeliveryService;
  deliveryInstruction: string;
  deliveryMetadata: string;
  discountCode: string;
  paymentMethod: PaymentMethod;
  cutlery: boolean;
  storeOrdersAttributes: StoreOrderAttrType[];
};

interface ICartGroupProps {
  outletId: string;
  outletFriendlyPath: string;
  onCheckout: (variables: CheckoutVariableType) => void;
}

const CartGroup: React.FC<ICartGroupProps> = ({children, outletId, outletFriendlyPath, onCheckout}) => {
  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const deliveryOption = usePersistentStore(s => s.deliveryOption);
  const deliveryService = useMixOrderStore(s => s.deliveryService);
  const paymentMethod = useMixOrderStore(s => s.paymentMethod);
  const cutlery = useMixOrderStore(s => s.cutlery);

  const {data: storesData} = useGetCartStoresByOutletQuery({
    variables: {outletFriendlyPaths: [outletFriendlyPath], deliveryOption},
    fetchPolicy: 'cache-and-network',
  });

  const {data: resMixinPromoMetadata} = useAppMetadataQuery();
  const mixinPromo = resMixinPromoMetadata?.app?.mixinPromo || [];

  const cartMixinPromo = useCartMixinPromo({
    outletId,
    outletFriendlyPath,
    mixinPromo,
  });

  const storeData = useMemo<ICartGroupContextProps['storeData']>(() => {
    if (!storesData) return;
    const rawStoreData = storesData.stores.map(({id, friendlyPath}) => {
      const store = mixOrderInput.stores.find(({storeId}) => storeId === id);
      if (!store?.checked) return;
      const storeDataSubtotal = store.menuItems.reduce((acc, item) => {
        const itemsSubtotal = item.subtotal * item.count;
        return acc + itemsSubtotal;
      }, 0);
      return {friendlyPath, subtotal: storeDataSubtotal};
    });
    return rawStoreData.filter(Boolean);
  }, [storesData, mixOrderInput]);

  const handleCheckout = () => {
    const rawStoreOrderAttributes = mixOrderInput.stores.map(({storeId, checked, menuItems: items}) => {
      if (!checked) return;
      const menuItemsArray = items.map(({menuItemId, menuOptions, remark, count}) => {
        const menuOptionItemIds = menuOptions.flatMap(({itemOptions}) => itemOptions.map(({itemOptionId}) => itemOptionId));
        const item = {id: menuItemId, menuOptionItemIds, remark};
        return Array(count).fill(item) as (typeof item)[];
      });
      const menuItems = menuItemsArray.flat();
      return {storeId, menuItems};
    });
    const storeOrdersAttributes = rawStoreOrderAttributes.filter(Boolean);
    onCheckout({
      outletId,
      deliveryOption,
      deliveryService,
      deliveryInstruction: mixOrderInput.deliveryInstruction,
      deliveryMetadata: mixOrderInput.deliveryMetadata,
      discountCode: cartMixinPromo.isFoundMixinPromoApplied ? '' : mixOrderInput.discount.code,
      paymentMethod,
      cutlery,
      storeOrdersAttributes,
    });
  };

  return (
    <CartGroupContext.Provider
      value={{
        outletId,
        outletFriendlyPath,
        cartMixinPromo,
        storesData,
        storeData,
        handleCheckout,
      }}>
      {children}
    </CartGroupContext.Provider>
  );
};

export default CartGroup;
