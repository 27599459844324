import React from 'react';
import {AvailableStatus, useGetMartSearchQuery} from '@graphql/generated/graphql';
import {MartCartBar, NoProductCard, ProductCard} from '..';
import {useRouter} from 'next/router';
import {useMartOrderStore} from 'stores';

interface ISearchProductItemsProps {
  searchString?: string;
  friendlyPath: string;
}

const SearchProductItems: React.FC<ISearchProductItemsProps> = ({searchString, friendlyPath}) => {
  const router = useRouter();
  const outletFriendlyPath = router.query.outletFriendlyPath as string;
  const martOrderInput = useMartOrderStore(s => s.getMartOrderInput(outletFriendlyPath));
  const {data} = useGetMartSearchQuery({
    variables: {
      searchString,
      storeFriendlyPath: friendlyPath,
    },
  });

  if (!data?.martSearch) return <NoProductCard />;

  const mart = martOrderInput?.stores?.find(({storeId}) => storeId === data?.martSearch[0].store.id);

  return (
    <div>
      <div className="grid grid-cols-2 gap-5 py-4 flex-1 bg-bgColor paddingHorizontal">
        {data.martSearch.map(menuItem => {
          const items = mart?.menuItems?.filter(({menuItemId}) => menuItemId === menuItem.id) || [];
          const itemCount = items.reduce((acc, {count}) => acc + count, 0);
          return (
            <ProductCard
              key={menuItem.id}
              martId={menuItem.store.id}
              storeAndCategoryActive={menuItem.availableStatus === AvailableStatus.Available}
              itemCount={itemCount}
              item={menuItem}
            />
          );
        })}
      </div>
      {!!martOrderInput?.stores?.length && <MartCartBar />}
    </div>
  );
};

export default SearchProductItems;
