import React from 'react';

interface ISubscriptionPlanContainerProps {
  planName: string;
  price: number;
}

const SubscriptionPlanContainer: React.FC<ISubscriptionPlanContainerProps> = ({planName, price}) => {
  return (
    <>
      <div className="flex flex-row justify-between items-center py-4 grayBottomBorder">
        <p className="poppins-semibold">{planName}</p>
        <p className="poppins-semibold text-primary">RM{price} / month</p>
      </div>

      <p className="mt-6 poppins-semibold">What can you get from this plan?</p>
      <div className="bg-white p-6 rounded-2xl my-3 flex flex-col gap-5">
        <div className="flex flex-row justify-between items-center">
          <img src="/icons/membership/price-tag.png" className="w-10 h-10 ml-3 mr-7" />
          <p className="poppins-medium font-sm flex-1">Lowest price guaranteed items</p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <img src="/icons/membership/member-deal.png" className="w-10 h-10 ml-3 mr-7" />
          <p className="poppins-medium font-sm flex-1">Exclusive promotions and member deals</p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <img src="/icons/membership/products.png" className="w-10 h-10 ml-3 mr-7" />
          <p className="poppins-medium font-sm flex-1">Priority access to new products</p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <img src="/icons/membership/delivery-truck.png" className="w-12 h-12 ml-3 mr-7" />
          <p className="poppins-medium font-sm flex-1">Delivery discount</p>
        </div>
      </div>
    </>
  );
};

export default React.memo(SubscriptionPlanContainer);
