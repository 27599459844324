import React, {useMemo} from 'react';
import {useRouter} from 'next/router';
import {MartMembershipPlan, useCancelMartMembershipMutation, useCreateMartMembershipMutation} from '@graphql/generated/graphql';
import {useFeedback, useLoader} from 'context';
import {loadStripe} from '@stripe/stripe-js';

interface ISubscribeBarProps {
  isUnsubscribe: boolean;
}

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const SubscribeBar: React.FC<ISubscribeBarProps> = ({isUnsubscribe}) => {
  const router = useRouter();
  const {setShowLoader} = useLoader();
  const {enqueueFeedback} = useFeedback();
  const [createSubscription] = useCreateMartMembershipMutation();
  const [cancelSubscription] = useCancelMartMembershipMutation();

  const handleSubscribe = async () => {
    setShowLoader(true);
    try {
      const {
        data: {
          createMartMembership: {checkoutSessionId, errors},
        },
      } = await createSubscription({
        variables: {
          planId: MartMembershipPlan.Basic,
        },
      });
      setShowLoader(false);
      if (checkoutSessionId) {
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({sessionId: checkoutSessionId});
        if (result.error) {
          setShowLoader(false);
          enqueueFeedback(result.error.message, true);
        }
      } else if (errors) {
        setShowLoader(false);
        enqueueFeedback(errors[0]['detail'], true);
      } else {
        router.push(`/mart/`);
        enqueueFeedback(`Subscribed to Basic Plan`);
      }
    } catch (error) {
      router.push(`/login?martSubscription=true`);
      enqueueFeedback('Login to subscribe');
    }
  };

  const handleUnsubscribe = async () => {
    setShowLoader(true);
    try {
      const {
        data: {
          cancelMartMembership: {membership, errors},
        },
      } = await cancelSubscription();
      setShowLoader(false);
      if (membership) {
        router.push(`/mart/`);
        enqueueFeedback(`Unsubscribed from Basic Plan`);
      } else if (errors) {
        setShowLoader(false);
        enqueueFeedback(errors[0]['detail'], true);
      }
    } catch (error) {
      router.push(`/login?martSubscription=true`);
      enqueueFeedback('Login to unsubscribe');
    }
  };

  return (
    <section>
      <div className="fixed bottom-0 max-w-md bg-white h-16 w-full navbarShadow px-5">
        <button
          onClick={isUnsubscribe ? handleUnsubscribe : handleSubscribe}
          className="bg-primary py-2.5 px-4 my-2 w-full rounded-full cursor-pointer flex flex-row items-center justify-center"
        >
          <p className="poppins-semibold text-white font-lg">{isUnsubscribe ? 'Unsubscribe' : 'Subscribe now'}</p>
        </button>
      </div>
    </section>
  );
};

export default SubscribeBar;
