import React from 'react';
import {GetMenuItemsQuery} from '@graphql/generated/graphql';
import {CartOutletType, CartStoreMenuOptionType} from 'stores';

type MenuItemType = CartOutletType['stores'][0]['menuItems'][0];

interface ICartStoreMenuItemProps {
  menuItemData: GetMenuItemsQuery['menuItems'][0];
  menuItem: MenuItemType;
  menuOptions: CartStoreMenuOptionType[];
  onEditItem: (item: MenuItemType) => void;
  storeChecked: boolean;
  unavailable?: boolean;
}

const CartStoreMenuItem: React.FC<ICartStoreMenuItemProps> = ({menuItemData, menuItem, menuOptions, onEditItem, storeChecked}) => {
  const {subtotal, remark, count} = menuItem;
  const itemOptions = menuOptions.flatMap(({menuOptionId, itemOptions}) => {
    return itemOptions.map(itemOption => {
      return {menuOptionId, ...itemOption};
    });
  });

  const disabled = !storeChecked;
  const opacity = disabled ? 'opacity-50' : 'opacity-100';

  return (
    <div className={`px-2 w-full transition flex justify-between p-3 ${opacity}`}>
      <div className="flex flex-row flex-1">
        <p className="foodCount">{count}x</p>
        <div>
          <p className="poppins-medium">{menuItemData?.name}</p>
          {itemOptions.map(({menuOptionId, itemOptionId, itemOptionName}) => (
            <p key={`${menuOptionId}-${itemOptionId}`} className="foodOptions">
              {itemOptionName}
            </p>
          ))}
          {!!remark && <p className="foodRemark">Notes: {remark}</p>}
          <button
            type="button"
            className="text-primary mt-2 poppins-medium font-sm focus:outline-none"
            onClick={() => onEditItem(menuItem)}
            disabled={disabled}
          >
            Edit
          </button>
        </div>
      </div>
      <p className="price">{(subtotal * count).toFixed(2)}</p>
    </div>
  );
};

export default CartStoreMenuItem;
