import React, {useReducer} from 'react';
import {Transition} from '@headlessui/react';
import {GetMixOrdersQuery} from '@graphql/generated/graphql';

import MixOrdersListItem from './MixOrdersListItem';
import useCountryData from '@lib/useCountryData';

interface ICollapsibleTabProps {
  mixOrders: GetMixOrdersQuery['mixOrders'];
  date: string;
  totalNet: number;
  isCashier: boolean;
}

const CollapsibleTab: React.FC<ICollapsibleTabProps> = ({date, mixOrders, totalNet, isCashier}) => {
  const [isOpen, toggleOpen] = useReducer(s => !s, true);
  const chevronState = isOpen ? 'rotate-0' : 'rotate-180';
  const {currency} = useCountryData(mixOrders[0].country);
  return (
    <div className="bg-white rounded-xl defaultShadow my-4">
      <div className="flex flex-row w-full px-3.5 py-3 justify-between rounded-xl cursor-pointer defaultShadow" onClick={toggleOpen}>
        <p className="poppins-semibold uppercase">{date}</p>
        <div className="flex items-center">
          {isCashier && (
            <p className="poppins-semibold text-primary mr-2">
              {currency} {totalNet.toFixed(2)}
            </p>
          )}
          <img className={`w-4 h-4 transform duration-200 ${chevronState}`} src="/chevron-up.svg"></img>
        </div>
      </div>
      <div className={`${isOpen ? 'pt-4' : 'pt-0'}`}>
        <Transition show={isOpen} enter="transition duration-200 transform origin-top" enterFrom="scale-y-0" enterTo="scale-y-100">
          {mixOrders.map((mixOrder, i) => (
            <MixOrdersListItem key={mixOrder.id} mixOrder={mixOrder} isLast={i == mixOrders.length - 1} />
          ))}
        </Transition>
      </div>
    </div>
  );
};

export default React.memo(CollapsibleTab);
