import React from 'react';
import StoreCard from './StoreCard';
import NoStoreCard from './NoStoreCard';
import {StoreListFragment} from '@graphql/generated/graphql';

interface IStoreListProps {
  stores: StoreListFragment[];
  showDistance?: boolean;
}

const StoreList: React.FC<IStoreListProps> = ({stores, showDistance = false}) => {
  if (!stores.length) {
    return (
      <section className="pb-32">
        <NoStoreCard />
      </section>
    );
  }

  return (
    <section className="pb-32">
      {stores.map(store => {
        return <StoreCard key={store.id} store={store} showDistance={showDistance} />;
      })}
    </section>
  );
};

export default StoreList;
