import {DeliveryOption, GetOngoingMixOrdersQuery} from '@graphql/generated/graphql';
import {add, format} from 'date-fns';

const timeRangeFormatter = (datetime: Date) => {
  const lowerBound = format(new Date(datetime), 'h:mm');
  const upperBound = format(add(new Date(datetime), {minutes: 10}), 'h:mm');

  return `${lowerBound} - ${upperBound}`;
};

const getEtaFromDeliveryOption = (mixOrder: GetOngoingMixOrdersQuery['mixOrders'][0]) => {
  const {deliveryOption, acceptedTime, deliveryBooking} = mixOrder;
  const isDelivery = deliveryOption === DeliveryOption.Delivery;

  if (!isDelivery) return timeRangeFormatter(add(new Date(acceptedTime), {minutes: 20}));
  if (!deliveryBooking?.eta) return '';
  return timeRangeFormatter(deliveryBooking.eta);
};

export default getEtaFromDeliveryOption;
