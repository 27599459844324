import React, {useContext, useEffect} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {DeliveryOption} from '@graphql/generated/graphql';
import {useMixOrderStore, usePersistentStore} from 'stores';
import {useGeolocation} from 'context';
import {Cart} from '@components/index';
import {CartGroupContext} from './CartGroup';

interface ICartDeliveryServicesProps {
  storeId?: string;
}

const CartDeliveryServices: React.FC<ICartDeliveryServicesProps> = ({storeId}) => {
  const router = useRouter();
  const outletFriendlyPath = router.query.friendlyPath as string;

  const {address} = useGeolocation();
  const {outletId} = useContext(CartGroupContext);

  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const setMixOrderInput = useMixOrderStore(s => s.setMixOrderInput);
  const deliveryService = useMixOrderStore(s => s.deliveryService);
  const setDeliveryService = useMixOrderStore(s => s.setDeliveryService);
  const deliveryOption = usePersistentStore(s => s.deliveryOption);

  const handleSetDeliveryData = (deliveryFee: number, deliveryMetadata: string) => {
    setMixOrderInput(outletFriendlyPath, {
      ...mixOrderInput,
      deliveryFee,
      deliveryMetadata,
    });
  };

  useEffect(() => {
    setDeliveryService(null);
    setMixOrderInput(outletFriendlyPath, {
      ...mixOrderInput,
      deliveryFee: 0,
      deliveryMetadata: '',
    });
  }, [deliveryOption]);

  if (deliveryOption !== DeliveryOption.Delivery) return null;

  return (
    <>
      <section className="bg-white rounded-lg defaultShadow pt-5 pb-4 px-5 my-3 flex flex-row items-center cursor-pointer">
        <Link href={`/ordering-location?outlet=${outletFriendlyPath}`}>
          <a className="flex flex-row justify-between items-center w-full">
            <div className="flex flex-col flex-1 justify-start items-start">
              <div className="flex flex-row justify-start items-center">
                <svg width="10" height="14" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6 0C9.3135 0 12 2.6865 12 6C12 7.30475 11.5722 8.502 10.8612 9.4825C10.8485 9.506 10.8465 9.53225 10.832 9.55475L6.832 15.5548C6.6465 15.833 6.334 16 6 16C5.666 16 5.3535 15.833 5.168 15.5548L1.168 9.55475C1.15325 9.53225 1.1515 9.506 1.13875 9.4825C0.42775 8.502 0 7.30475 0 6C0 2.6865 2.6865 0 6 0ZM6 8C7.1045 8 8 7.1045 8 6C8 4.8955 7.1045 4 6 4C4.8955 4 4 4.8955 4 6C4 7.1045 4.8955 8 6 8Z"
                    fill="#000000"
                  />
                </svg>
                <p className="poppins-semibold ml-2">Deliver to</p>
              </div>

              <p className="poppins-medium line-clamp-2 mt-2">{address}</p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#000000" className="h-4 w-4 ml-2">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9 5l7 7-7 7" />
            </svg>
          </a>
        </Link>
      </section>

      <section className="pt-2 flex-col">
        <p className="sectionHeaderSemibold">Delivery Service Options</p>
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <Cart.PreviewLalamove
            outletId={outletId}
            storeId={storeId}
            deliveryService={deliveryService}
            setDeliveryService={setDeliveryService}
            setDeliveryData={handleSetDeliveryData}
          />
          <Cart.PreviewPandago
            outletId={outletId}
            storeId={storeId}
            deliveryService={deliveryService}
            setDeliveryService={setDeliveryService}
            setDeliveryData={handleSetDeliveryData}
          />
        </div>
        <Cart.DeliveryInstruction />
      </section>
    </>
  );
};

export default CartDeliveryServices;
