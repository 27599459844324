import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {format} from 'date-fns';
import {DeliveryOption, GetMixOrdersQuery, OrderState, PaymentMethod} from '@graphql/generated/graphql';
import {useLoader} from 'context';
import useCountryData from '@lib/useCountryData';
import extractOutlet from '@lib/helpers/outlet-name-area';

const PAYMENT_METHOD_IMG: Record<PaymentMethod, string> = {
  [PaymentMethod.Card]: '/payment-method-card.png',
  [PaymentMethod.Fpx]: '/payment-method-fpx.png',
  [PaymentMethod.Grabpay]: '/payment-method-grabpay.png',
  [PaymentMethod.Cash]: '/payment-method-cash.png',
  [PaymentMethod.Qr]: '/payment-method-qr.png',
};

const DELIVERY_OPTION_ICON: Record<DeliveryOption, JSX.Element> = {
  [DeliveryOption.Delivery]: <Image src="/icons/delivery-icon.svg" width={24} height={24} />,
  [DeliveryOption.DineIn]: <Image src="/icons/dine-in-icon.svg" width={24} height={24} />,
  [DeliveryOption.Pickup]: <Image src="/icons/pickup-icon.svg" width={24} height={24} />,
};

interface IMixOrdersListItemProps {
  mixOrder: GetMixOrdersQuery['mixOrders'][0];
  isLast: boolean;
}

const MixOrdersListItem: React.FC<IMixOrdersListItemProps> = ({
  mixOrder: {
    id,
    orderCode,
    country,
    state,
    deliveryOption,
    finalPrice,
    acceptedTime,
    paymentMethod,
    store: {name: storeName},
  },
  isLast,
}) => {
  const {setShowLoader} = useLoader();
  const {currency} = useCountryData(country);
  const {outletName, outletArea} = extractOutlet(storeName);
  return (
    <Link key={id} href={`/mix-orders/${id}`} passHref>
      <a onClick={() => setShowLoader(true)}>
        <div className={`relative flex flex-col justify-between mx-5 mb-1 pb-4 ${!isLast && 'lightGrayBottomBorder mb-4'}`}>
          <div className="flex flex-row justify-between items-start">
            <p className="poppins-semibold">{orderCode}</p>
            <div className="flex flex-row justify-between items-start">
              <p className="poppins-semibold">
                {currency} {finalPrice.toFixed(2)}
              </p>
              <img src={PAYMENT_METHOD_IMG[paymentMethod]} alt={`${paymentMethod}_icon`} className="ml-1 mt-0.5 w-4 h-4" />
            </div>
          </div>
          <div className="flex flex-row justify-between items-start">
            <div className="w-9/12">
              <p className="subTextGray">{outletName}</p>
              {outletArea && <p className="subTextGray">{outletArea}</p>}
            </div>
            <p className="subTextGray">{format(new Date(acceptedTime), 'h:mm a')}</p>
          </div>
          <div className="flex flex-row justify-between items-start mt-2">
            <div className="flex flex-row justify-between items-center">
              {DELIVERY_OPTION_ICON[deliveryOption]}
              <span className="capitalize poppins-medium font-sm text-primary ml-2">
                {deliveryOption.indexOf('_') != -1 ? deliveryOption.replace('_', ' ') : deliveryOption}
              </span>
            </div>
            <p className={`poppins-semibold text-success ${state === OrderState.Cancelled && 'text-error'}`}>
              {state === OrderState.Readied ? 'READY' : state.toUpperCase()}
            </p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default React.memo(MixOrdersListItem);
