import React, {useEffect, useState} from 'react';
import {flushSync} from 'react-dom';
import {DeliveryOption} from '@graphql/generated/graphql';
import {DELIVERY_SELECTIONS} from 'modules';
import {usePersistentStore} from 'stores';

interface ISearchDeliveryOptionProps {}

const SearchDeliveryOption: React.FC<ISearchDeliveryOptionProps> = React.memo(() => {
  const [hydrated, setHydrated] = useState<boolean>(false);

  const deliveryOption = usePersistentStore(s => s.deliveryOption);
  const setDeliveryOption = usePersistentStore(s => s.setDeliveryOption);

  const deliveryTabs = Object.values(DeliveryOption)
    .map(option => DELIVERY_SELECTIONS[option])
    .filter(value => Object.keys(value).length !== 0);

  const handleDeliveryOption = (option: DeliveryOption) => {
    // @ts-ignore
    if (!document?.startViewTransition) return setDeliveryOption(option);
    // @ts-ignore
    document.startViewTransition(() => {
      flushSync(() => {
        setDeliveryOption(option);
      });
    });
  };

  useEffect(() => {
    setTimeout(() => setHydrated(true), 0);
  }, []);

  if (!hydrated) return null;

  return (
    <section className="max-w-md z-10 py-3 bg-white defaultShadow paddingHorizontalSmall flex flex-row">
      {deliveryTabs.map(({text, option}) => (
        <div
          key={option}
          onClick={() => handleDeliveryOption(option)}
          className={`w-1/3 text-center poppins-semibold py-0.5 mx-2 cursor-pointer rounded-full transition duration-100 ${
            deliveryOption === option ? 'text-white bg-primary' : 'text-gray grayRoundBorder'
          }`}>
          {text}
        </div>
      ))}
    </section>
  );
});

export default SearchDeliveryOption;
