import React, {useEffect, useMemo, useState} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {AvailableStatus, OutletMartPageQuery} from '@graphql/generated/graphql';
import {useMartOrderStore} from 'stores';
import {produce} from 'immer';
import {martItemsSimilar} from '@lib/cart/itemsSimilar';

interface IProductCardProps {
  martId: string;
  storeAndCategoryActive: boolean;
  itemCount: number;
  item: OutletMartPageQuery['mart']['menuSection']['menuCategories'][0]['menuItems'][0];
}

const ProductCard: React.FC<IProductCardProps> = ({
  martId,
  storeAndCategoryActive,
  itemCount,
  item: {id, name, image, description, withPromo, priceWithTax, priceWithPromo, priceWithMembership, withMembership, availableStatus},
}) => {
  const router = useRouter();
  const {outletFriendlyPath} = router.query;
  const availableOverride = storeAndCategoryActive && availableStatus === AvailableStatus.Available;
  const martOrderInput = useMartOrderStore(s => s.getMartOrderInput(outletFriendlyPath as string));
  const setMartOrderInput = useMartOrderStore(s => s.setMartOrderInput);
  const [count, setCount] = useState<number>(itemCount);
  const basePrice = withMembership ? priceWithMembership : withPromo ? priceWithPromo : priceWithTax;

  const handleAddToCart = (e: React.MouseEvent, count: number, edit: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    if (count < 0 && !edit) return;
    const newCount = count < 0 ? 0 : count;
    const subtotal = basePrice * newCount;
    const newItem = {
      menuItemId: id,
      subtotal: newCount > 0 ? subtotal / newCount : 0,
      tax: 0,
      count: newCount,
    };

    const newMartOrderInput = produce(martOrderInput, draft => {
      const store = draft.stores.find(({storeId}) => storeId === martId);

      if (!store) {
        const newStore = {
          storeId: martId,
          menuItems: [newItem],
        };
        draft.stores = [...draft.stores, newStore];
        return;
      }

      if (edit) {
        const originalItem = {
          menuItemId: newItem.menuItemId,
        };
        const similarWithOld = store.menuItems.find(martItemsSimilar(originalItem));
        const similarWithNew = store.menuItems.find(martItemsSimilar(newItem));

        if (similarWithNew && similarWithOld) {
          similarWithNew.count = newItem.count;
          if (!martItemsSimilar(similarWithOld)(similarWithNew)) {
            store.menuItems = store.menuItems.filter(item => !martItemsSimilar(originalItem)(item));
          }
        } else if (similarWithOld) {
          Object.keys(similarWithOld).forEach(key => (similarWithOld[key] = newItem[key]));
        } else {
          store.menuItems.push(newItem);
        }
      } else {
        const similarItem = store.menuItems.find(martItemsSimilar(newItem));
        if (similarItem) {
          similarItem.count += newItem.count;
        } else {
          store.menuItems.push(newItem);
        }
      }

      store.menuItems = store.menuItems.filter(({count}) => count > 0);
      draft.stores = draft.stores.filter(({menuItems}) => menuItems.length > 0);
    });

    setMartOrderInput(outletFriendlyPath as string, newMartOrderInput);
  };

  useEffect(() => {
    if (count === itemCount || count === -1) return;
    setCount(itemCount);
  }, [count, itemCount]);

  const children = (
    <div className="defaultShadow h-60 rounded-2xl bg-white flex flex-col justify-between">
      <div className="relative">
        <img src={image ?? '/placeholder-v2.png'} alt={name} className="w-full h-24 object-cover rounded-t-2xl" />
        <div className="absolute bottom-2 right-2">
          {count > 0 ? (
            <div className="flex flex-row justify-between items-center bg-white orangeRoundBorder px-2 py-1">
              <button onClick={e => handleAddToCart(e, count - 1, true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path fill="none" stroke="#ff9906" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14" />
                </svg>
              </button>
              <p className="poppins-semibold mx-3">{count}</p>
              <button onClick={e => handleAddToCart(e, count + 1, true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <path fill="none" stroke="#ff9906" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h7m7 0h-7m0 0V5m0 7v7" />
                </svg>
              </button>
            </div>
          ) : (
            <button onClick={e => handleAddToCart(e, count + 1, false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" className="cursor-pointer bg-white rounded-2xl" viewBox="0 0 1024 1024">
                <path
                  fill="#ff9906"
                  d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col flex-1 justify-start items-start py-3 px-4">
        <div className="flex flex-row justify-between items-start w-full">
          <div className="flex flex-col justify-start items-start">
            {withMembership ? (
              <p className="poppins-semibold overflow-hidden font-lg">RM {priceWithMembership.toFixed(2)}</p>
            ) : (
              <>
                <p className="poppins-semibold overflow-hidden font-lg"> RM {withPromo ? priceWithPromo.toFixed(2) : priceWithTax.toFixed(2)}</p>
                {withPromo && <p className="poppins-semibold overflow-hidden line-through subTextGray font-sm">RM {priceWithTax.toFixed(2)}</p>}
              </>
            )}
            <div className="flex flex-row justify-between items-start">
              {withMembership ? (
                <p className="poppins-medium font-sm text-primary">Saved RM {(priceWithTax - priceWithMembership).toFixed(2)}</p>
              ) : (
                <>
                  <img src="/coox-mart.png" className="w-4 h-4 mr-2" />
                  <p className="poppins-semibold overflow-hidden text-primary">RM {priceWithMembership.toFixed(2)}</p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <p className="text-gray poppins-medium line-clamp-2 leading-tight">{name}</p>
          <p className="subTextGray font-sm line-clamp-2 mt-2">6 x 1 cartons</p>
          {!!description?.trim()?.length && <p className="subTextGray font-sm line-clamp-2 mt-2">{description}</p>}
        </div>
      </div>
    </div>
  );

  return availableOverride ? (
    <Link href={`/mart/${outletFriendlyPath}/menuItem/${id}${itemCount > 0 && `?isEdit=true&count=${itemCount}`}`}>
      <a className="cursor-pointer">{children}</a>
    </Link>
  ) : (
    <div className="py-4 opacity-50 lightGrayBottomBorder">{children}</div>
  );
};

export default React.memo(ProductCard);
