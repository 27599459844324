import React, {useCallback, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {useApolloClient} from '@apollo/client';
import {format} from 'date-fns';
import Link from 'next/link';
import {GetMixOrdersQuery, OrderState, useGetMeQuery, useGetMixOrdersQuery} from '@graphql/generated/graphql';
import {ORDERS_STATES} from '@lib/helpers/helper';
import {Spinner} from '..';

import CollapsibleTab from './CollapsibleTab';

export type SortedOrder = {
  date: string;
  mixOrders: GetMixOrdersQuery['mixOrders'];
  totalNet: number;
};

interface IMixOrdersListProps {}

const MixOrdersList: React.FC<IMixOrdersListProps> = () => {
  const {cache} = useApolloClient();
  const [sortedOrders, setSortedOrders] = useState<SortedOrder[]>([]);

  const cashierData = useGetMeQuery().data;
  const isCashier = cashierData?.me?.isCashier;

  const {data, fetchMore} = useGetMixOrdersQuery({
    variables: {states: ORDERS_STATES, limit: 10, offset: 0},
  });

  const loadMore = useCallback(() => {
    fetchMore({variables: {offset: data.mixOrders.length}});
  }, [fetchMore, data]);

  const clearOrdersCache = () => {
    cache.evict({id: 'ROOT_QUERY', fieldName: 'mixOrders'});
    cache.gc();
  };

  useEffect(() => {
    return () => {
      clearOrdersCache();
    };
  }, []);

  useEffect(() => {
    if (!data?.mixOrders?.length) return;

    data.mixOrders.map(mixOrder => {
      const date = format(new Date(mixOrder.acceptedTime), 'dd MMM yyyy');
      setSortedOrders(prev => {
        const hasDate = prev.find(obj => obj.date === date);
        if (hasDate) {
          return prev.map(obj => {
            const hasId = obj.mixOrders.find(({id}) => id === mixOrder.id);
            if (obj.date === date && !hasId) {
              return {
                ...obj,
                mixOrders: [...obj.mixOrders, mixOrder],
                totalNet: mixOrder.state === OrderState.Cancelled ? obj.totalNet : obj.totalNet + mixOrder.finalPrice,
              };
            }
            return obj;
          });
        }
        return [
          ...prev,
          {
            date: date,
            mixOrders: [mixOrder],
            totalNet: mixOrder.state === OrderState.Cancelled ? 0 : mixOrder.finalPrice,
          },
        ];
      });
    });
  }, [data]);

  if (!data) return <Spinner />;

  const {mixOrders} = data;

  return (
    <section>
      {!!mixOrders.length ? (
        <section className="pb-32 paddingHorizontal">
          <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore useWindow>
            {sortedOrders.map(({mixOrders, date, totalNet}) => (
              <CollapsibleTab key={date} mixOrders={mixOrders} date={date} totalNet={totalNet} isCashier={isCashier} />
            ))}
          </InfiniteScroll>
        </section>
      ) : (
        <div className="fixed flex justify-center max-w-md mx-auto w-full paddingHorizontal">
          <Link href="/" passHref>
            <a className="bg-white flex flex-col justify-center items-center py-6 rounded-2xl shadow">
              <img src="/decorators/no-items.svg" alt="no-items" className="w-1/2" />
              <div className="flex flex-col text-center mt-6 gap-2">
                <p className="poppins-semibold font-lg">No orders yet</p>
                <p className="subTextGray">Click here to explore our stores</p>
              </div>
            </a>
          </Link>
        </div>
      )}
    </section>
  );
};

export default MixOrdersList;
