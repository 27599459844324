import React, {useContext} from 'react';
import {useMixOrderStore} from 'stores';
import {CartGroupContext} from './CartGroup';

interface ICartDeliveryInstructionProps {}

const CartDeliveryInstruction: React.FC<ICartDeliveryInstructionProps> = () => {
  const {outletFriendlyPath} = useContext(CartGroupContext);
  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const setMixOrderInput = useMixOrderStore(s => s.setMixOrderInput);
  const deliveryInstruction = mixOrderInput.deliveryInstruction;

  const handleChangeInstruction = (value: string) => {
    setMixOrderInput(outletFriendlyPath, {
      ...mixOrderInput,
      deliveryInstruction: value,
    });
  };

  return (
    <div className="my-3 flex flex-col">
      <label htmlFor="deliveryInstruction" className="poppins-semibold font-sm">
        Rider Instructions
      </label>
      <input
        id="deliveryInstruction"
        name="deliveryInstruction"
        type="text"
        placeholder="Meet me at lobby..."
        className="appearance-none mt-2 grayBoxBorder smallText placeholder-gray focus:outline-none focus:border-gray focus:ring-0"
        value={deliveryInstruction}
        onChange={e => handleChangeInstruction(e.target.value)}
      />
    </div>
  );
};

export default CartDeliveryInstruction;
