import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {MartSpendingTracker, Membership} from '@graphql/generated/graphql';
import {SavingsChart} from '..';

interface IProfileMartCardProps {
  spendingTracker: Pick<MartSpendingTracker, 'spendActual' | 'spendRegular' | 'spendSaved'>;
  martMembershipsSubscribed: Pick<Membership, 'id' | 'name' | 'price'>;
}

const ProfileMartCard: React.FC<IProfileMartCardProps> = ({spendingTracker, martMembershipsSubscribed}) => {
  const {spendRegular, spendSaved} = spendingTracker;

  if (!martMembershipsSubscribed && !spendRegular) return null;

  return (
    <section>
      <div className="flex flex-row justify-between items-center paddingHorizontalSmall py-5">
        <Image src="/coox-mart.png" width={55} height={55} />
        {(() => {
          if (!martMembershipsSubscribed) {
            if (!!spendRegular) {
              return (
                <div>
                  <p className="poppins-medium flex-1">
                    You've spent <span className="text-primary poppins-semibold">RM{spendRegular}</span> on COOX MART.
                  </p>
                  <Link href={'/mart/membership'} passHref>
                    <a className="poppins-medium font-sm text-white rounded-full bg-primary pl-3 pr-2 my-1 py-1.5 text-white focus:outline-none flex flex-row items-center gap-1">
                      <p>Save more with our subscription plans</p>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#ffffff" className="h-4 w-4">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9 5l7 7-7 7" />
                      </svg>
                    </a>
                  </Link>
                </div>
              );
            }
          } else {
            const {name: membershipName} = martMembershipsSubscribed;
            if (!spendSaved) {
              return (
                <p className="poppins-medium flex-1 ml-6">Start spending with your COOX MART {membershipName}! Your savings will appear here.</p>
              );
            } else {
              return (
                <div className="flex-1 ml-6">
                  <p className="poppins-semibold mb-3">COOX MART {membershipName}</p>
                  <SavingsChart spendings={spendingTracker} isDense />
                </div>
              );
            }
          }
        })()}
      </div>
    </section>
  );
};

export default React.memo(ProfileMartCard);
