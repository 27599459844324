import React from 'react';
import {SelectItemType} from '@components/index';

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

interface IMenuItemOptionsItemProps {
  menuOption: SelectItemType['menuOption'];
  itemOption: SelectItemType['itemOption'];
  handleSelect: (menuOption: SelectItemType['menuOption'], itemOption: SelectItemType['itemOption']) => void;
  selected?: boolean;
  unavailable?: boolean;
}

const MenuItemOptionsItem: React.FC<IMenuItemOptionsItemProps> = ({menuOption, itemOption, handleSelect, selected = false, unavailable = true}) => {
  const {name, priceWithTax} = itemOption;
  return (
    <div
      className={classNames('flex flex-row justify-between items-center mt-2 w-full cursor-pointer', unavailable && 'cursor-default opacity-50')}
      onClick={() => !unavailable && handleSelect(menuOption, itemOption)}
    >
      <div className="flex flex-row flex-1 items-center justify-start">
        <div className="my-2">
          {selected ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                fill="#ff9906"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM9.29 16.29L5.7 12.7a.996.996 0 1 1 1.41-1.41L10 14.17l6.88-6.88a.996.996 0 1 1 1.41 1.41l-7.59 7.59a.996.996 0 0 1-1.41 0z"
              />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                fill="#b7b7b7"
                d="M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"
              />
            </svg>
          )}
        </div>
        <p className={`poppins-medium flex-wrap ml-3 ${selected && 'text-primary'}`}>{name}</p>
      </div>
      <div className="flex flex-col items-end justify-end">
        <p className="text-right poppins-medium">+ {priceWithTax.toFixed(2)}</p>
        {unavailable && <p className="closed text-right">Unavailable</p>}
      </div>
    </div>
  );
};

export default React.memo(MenuItemOptionsItem);
