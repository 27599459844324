import React from 'react';
import useCountryData from '@lib/useCountryData';

interface IMenuItemSubmitButtonProps {
  country: string;
  isEdit: boolean;
  count: number;
  subtotal: number;
  handlePress: () => void;
}

const MenuItemSubmitButton: React.FC<IMenuItemSubmitButtonProps> = ({country, isEdit, count, subtotal, handlePress}) => {
  const {currency} = useCountryData(country);
  return (
    <section>
      <div className="fixed bottom-0 max-w-md bg-white h-16 w-full navbarShadow px-5">
        <button className="bg-primary py-3 my-2 w-full rounded-full cursor-pointer" onClick={handlePress}>
          <p className="text-white poppins-semibold">
            {(() => {
              if (count === 0) return isEdit ? 'Remove From Cart' : 'Back To Menu';
              return isEdit ? `Update Cart - ${subtotal.toFixed(2)}` : `Add To Cart - ${currency} ${subtotal.toFixed(2)}`;
            })()}
          </p>
        </button>
      </div>
    </section>
  );
};

export default MenuItemSubmitButton;
