import React, {useEffect, useState} from 'react';
import {PaymentMethod, DeliveryOption, useGetMeQuery, useAppMetadataQuery} from '@graphql/generated/graphql';
import {useMixOrderStore, usePersistentStore} from 'stores';
import {getCountryData} from '@lib/useCountryData';
import myIP from '@lib/myIP';

type PaymentMethodsType = {
  name: string;
  desc?: string;
  imageUrl: string;
  method: PaymentMethod;
  cashierOnly: boolean;
};

const PAYMENT_METHODS: PaymentMethodsType[] = [
  {
    name: 'GrabPay',
    desc: 'No processing fee',
    imageUrl: '/payment-method-grabpay.png',
    method: PaymentMethod.Grabpay,
    cashierOnly: false,
  },
  {
    name: 'FPX',
    desc: '+RM 1.10 processing fee',
    imageUrl: '/payment-method-fpx.png',
    method: PaymentMethod.Fpx,
    cashierOnly: false,
  },
  {
    name: 'Card',
    desc: '+RM 1.10 processing fee',
    imageUrl: '/payment-method-card.png',
    method: PaymentMethod.Card,
    cashierOnly: false,
  },
  {
    name: 'Cash',
    imageUrl: '/payment-method-cash.png',
    method: PaymentMethod.Cash,
    cashierOnly: true,
  },
  {
    name: 'QR',
    imageUrl: '/payment-method-qr.png',
    method: PaymentMethod.Qr,
    cashierOnly: true,
  },
];

interface ICartPaymentMethodsProps {}

const CartPaymentMethods: React.FC<ICartPaymentMethodsProps> = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodsType[]>([]);

  const deliveryOption = usePersistentStore(s => s.deliveryOption);
  const paymentMethod = useMixOrderStore(s => s.paymentMethod);
  const setPaymentMethod = useMixOrderStore(s => s.setPaymentMethod);

  const {data: appMetadata} = useAppMetadataQuery();
  const {data: meData} = useGetMeQuery();

  useEffect(() => {
    (async () => {
      if (appMetadata?.app) {
        const getCountryPaymentMethods = async () => {
          if (!appMetadata?.app) return [];
          const operatingCountries = appMetadata.app.operatingCountries;
          const consumerCountry = appMetadata.app.consumerCountry;
          const currentCountry = consumerCountry || (await myIP()).country || 'MY';
          const {countryData} = getCountryData(currentCountry, operatingCountries);
          return countryData?.currency?.paymentMethods ?? [];
        };
        const countryMethods = await getCountryPaymentMethods();
        const countryPaymentMethods = PAYMENT_METHODS.filter(pm => countryMethods.includes(pm.method) || pm.cashierOnly);
        setPaymentMethods(countryPaymentMethods);
      }
    })();
  }, [appMetadata?.app]);

  const isCashier = meData?.me?.isCashier;
  const isDelivery = deliveryOption === DeliveryOption.Delivery;

  return (
    <section className="my-4">
      <p className="sectionHeaderSemibold">Payment Method</p>

      {paymentMethods.map(({name, desc, imageUrl, method, cashierOnly}) => {
        const isConsumerOrDelivery = !isCashier || isDelivery;
        if (cashierOnly && isConsumerOrDelivery) return null;
        return (
          <div
            key={method}
            onClick={() => setPaymentMethod(method)}
            className={`bg-white defaultShadow flex flex-row items-center py-2 px-4 rounded-lg my-2.5 cursor-pointer ${
              paymentMethod === method && 'orangeBoxBorder orangeShadow'
            }`}>
            <div className="flex flex-row justify-start items-center">
              <img src={imageUrl} alt={name} className="flex-shrink-0 w-7 h-7 mr-3 object-contain" />
              <div className="flex flex-col justify-start items-start">
                <p className="poppins-semibold">{name}</p>
                {!!desc && <p className="subTextGray">{desc}</p>}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default CartPaymentMethods;
