import React, {useContext, useEffect} from 'react';
import {AvailableStatus, GetCartStoresByOutletQuery, GetMenuItemsQuery} from '@graphql/generated/graphql';
import {CartOutletType, useMixOrderStore} from 'stores';
import {useRouter} from 'next/router';
import {produce} from 'immer';
import {CartGroupContext} from '../CartGroup';
import delayMilliseconds from '@lib/delayMilliseconds';
import Cart from '..';

interface ICartStoreProps {
  store: CartOutletType['stores'][0];
  storeData: GetCartStoresByOutletQuery['stores'][0];
  menuItemsData: GetMenuItemsQuery;
  discount: CartOutletType['discount']['data'][0] | null;
}

const CartStore: React.FC<ICartStoreProps> = ({menuItemsData, storeData: {id, name, active}, store: {checked, menuItems}, discount}) => {
  const router = useRouter();
  const {outletFriendlyPath} = useContext(CartGroupContext);

  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const setMixOrderInput = useMixOrderStore(s => s.setMixOrderInput);

  const handleCheckStore = async (value: boolean) => {
    await delayMilliseconds();
    setMixOrderInput(
      outletFriendlyPath,
      produce(mixOrderInput, draft => {
        const store = draft.stores.find(({storeId}) => storeId === id);
        store.checked = value;
        draft.discount.data = [];
        if (draft.stores.reduce((acc, store) => acc + store.menuItems.length, 0) <= 0) draft.stores = [];
      }),
    );
  };

  const handleEditItem = (item: CartOutletType['stores'][0]['menuItems'][0]) => {
    const queryString = [];
    queryString.push(`isEdit=true`);
    queryString.push(`menuOptions=${JSON.stringify(item.menuOptions)}`);
    queryString.push(`remark=${item.remark || ''}`);
    queryString.push(`count=${item.count}`);
    router.push(`/outlets/${outletFriendlyPath}/menuItem/${item.menuItemId}?${queryString.join('&')}`);
  };

  useEffect(() => {
    if (!active) handleCheckStore(false);
  }, [active]);

  useEffect(() => {
    if (!menuItemsData) return;
    const newMixOrderInput = produce(mixOrderInput, draft => {
      for (let store of draft.stores) {
        for (let item of store.menuItems) {
          const itemData = menuItemsData.menuItems.find(({id}) => id === item.menuItemId);
          if (itemData?.availableStatus === AvailableStatus.Unavailable) {
            store.menuItems = store.menuItems.filter(({menuItemId}) => menuItemId !== item.menuItemId);
          }
        }
      }
    });
    setMixOrderInput(outletFriendlyPath, newMixOrderInput);
  }, [menuItemsData]);

  return (
    <div className={`flex flex-col orangeBoxBorder my-3 py-3 px-2 ${!active && 'opacity-50'}`}>
      <div className="flex items-start w-full ml-1 mb-3 cursor-pointer" onClick={() => active && handleCheckStore(!checked)}>
        <div className={`border-2 border-primary w-5 h-5 rounded flex-shrink-0 ${checked && 'bg-primary'}`}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-full h-full text-white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <div className="flex flex-col w-11/12">
          <p className="poppins-semibold ml-3 line-clamp-2">{name}</p>
          <p className="poppins-semibold ml-3 text-red-500">{active ? '' : 'CLOSED'}</p>
        </div>
      </div>

      <div className="w-full duration-200">
        {menuItems.map(menuItem => {
          const menuItemData = menuItemsData?.menuItems?.find(({id}) => id === menuItem.menuItemId);
          return (
            <Cart.StoreMenuItem
              key={`${menuItem.menuItemId}-${menuItem.remark}-${menuItem.count}-${menuItem.menuOptions}`}
              menuItemData={menuItemData}
              menuItem={menuItem}
              menuOptions={menuItem.menuOptions}
              onEditItem={handleEditItem}
              storeChecked={checked}
            />
          );
        })}
      </div>

      {!!discount?.message && (
        <p className={`poppins-medium font-sm duration-200 px-2 ${discount.amount > 0 ? 'text-success' : 'text-error'}`}>
          Discount: {discount.message}
        </p>
      )}
    </div>
  );
};

export default CartStore;
