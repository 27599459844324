const extractOutlet = (name: string) => {
  const areaIndex = name.indexOf('(');
  let outletName = name;
  let outletArea = null;
  if (areaIndex != -1) {
    outletArea = name.substring(areaIndex + 1, name.length - 1);
    outletName = name.substring(0, areaIndex);
  }
  return {outletName, outletArea};
};

export default extractOutlet;
