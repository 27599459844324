import React from 'react';

interface IMenuItemRemarkProps {
  remark: string;
  handleChange: (remark: string) => void;
}

const MenuItemRemark: React.FC<IMenuItemRemarkProps> = ({remark, handleChange}) => {
  return (
    <section>
      <div className="paddingHorizontal flex flex-col mt-3">
        <div className="flex flex-row items-center">
          <p className="sectionHeaderBold">Order Notes</p>
          <p className="subTextGray ml-3">Optional</p>
        </div>
        <input
          placeholder="Add a request"
          className="grayBoxBorder py-3 bg-bgColor px-4 focus:outline-none focus:border-gray focus:ring-0 poppins-medium font-sm my-2 placeholder-gray"
          defaultValue={remark}
          onChange={e => handleChange(e.target.value)}
        />
      </div>
    </section>
  );
};

export default MenuItemRemark;
