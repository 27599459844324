import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import {GetMeQuery} from '@graphql/generated/graphql';

interface IProfileCardProps {
  me: GetMeQuery['me'];
  handleClick: () => void;
}

const ProfileCard: React.FC<IProfileCardProps> = ({me: {name, email, phoneNumber}, handleClick}) => {
  return (
    <section>
      <div className="bg-white flex-1 px-7 py-5 orangeShadow rounded-3xl mb-7">
        <div className="flex flex-row justify-between items-center">
          <p className="poppins-semibold">{name}</p>
          <Link href="/account/update" passHref>
            <a onClick={handleClick}>
              <Image src="/icons/edit-icon.svg" width={18} height={18} />
            </a>
          </Link>
        </div>
        <p className="subTextGray">{email}</p>
        <p className="subTextGray">{phoneNumber}</p>
      </div>
    </section>
  );
};

export default ProfileCard;
