import {DeliveryOption} from '@graphql/generated/graphql';

export type DeliveryDetailsType = {
  text: string;
  option: DeliveryOption;
};

type DeliverySelectionsType = {
  pickup: DeliveryDetailsType;
  dine_in: DeliveryDetailsType;
  delivery: DeliveryDetailsType;
};

const DELIVERY_SELECTIONS: DeliverySelectionsType = {
  pickup: {
    text: 'PICKUP',
    option: DeliveryOption.Pickup,
  },
  dine_in: {
    text: 'DINE IN',
    option: DeliveryOption.DineIn,
  },
  delivery: {
    text: 'DELIVERY',
    option: DeliveryOption.Delivery,
  },
};

export default DELIVERY_SELECTIONS;
