import React from 'react';
import {useMixOrderStore} from 'stores';

interface ICartCutleryProps {}

const CartCutlery: React.FC<ICartCutleryProps> = () => {
  const cutlery = useMixOrderStore(s => s.cutlery);
  const setCutlery = useMixOrderStore(s => s.setCutlery);

  return (
    <section className="mt-6 mb-16">
      <div className="flex justify-between items-center mb-1">
        <p className="sectionHeaderSemibold">{cutlery ? 'With cutlery' : 'No cutlery'}</p>
        <button
          type="button"
          className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none"
          role="switch"
          aria-checked="false"
          onClick={() => setCutlery(!cutlery)}
        >
          <span className="sr-only">Use setting</span>
          <span aria-hidden="true" className="pointer-events-none absolute w-full h-full rounded-md"></span>
          <span
            aria-hidden="true"
            className={`pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200 ${
              cutlery ? 'bg-primary' : 'bg-lightGray'
            }`}
          ></span>
          <span
            aria-hidden="true"
            className={`pointer-events-none absolute left-0 inline-block h-5 w-5 shadow-md rounded-full bg-white transform ring-0 transition-transform ease-in-out duration-200 ${
              cutlery ? 'translate-x-5' : 'translate-x-0'
            }`}
          ></span>
        </button>
      </div>
      <p className="subTextGray">Let's help reduce plastic waste!</p>
      <p className="subTextGray">Select only if you REALLY need it.</p>
    </section>
  );
};

export default CartCutlery;
