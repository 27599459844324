import React from 'react';
import Link from 'next/link';

interface INoProductCardProps {}

const NoProductCard: React.FC<INoProductCardProps> = () => {
  return (
    <Link href="/ordering-location" passHref>
      <a className="bg-white flex flex-col justify-center items-center m-6 p-6 shadow rounded-lg">
        <div className="flex justify-center h-24 mb-6">
          <img src="/decorators/no-stores.svg" alt="no-stores" />
        </div>
        <div className="flex flex-col text-center">
          <p className="poppins-semibold font-lg">No product found.</p>
        </div>
      </a>
    </Link>
  );
};

export default NoProductCard;
