import React, {useMemo} from 'react';
import Link from 'next/link';
import extractOutlet from '@lib/helpers/outlet-name-area';
import {GetOutletsQuery, GetOutletsWithMartQuery} from '@graphql/generated/graphql';
import {CartOutletsType} from 'pages/cart';
import {MartCartOutletsType} from 'pages/mart/cart';
import {CartOutletType, MartCartOutletType} from 'stores';

interface ICartCardProps {
  outletData: GetOutletsQuery['outlets'][0] | GetOutletsWithMartQuery['outletsWithMart'][0];
  cartOutlet: CartOutletsType[0] | MartCartOutletsType[0];
  handleRemoveOutlet: (cartOutlet: CartOutletsType[0] | MartCartOutletsType[0]) => void;
  getItemsCount: (outlet: CartOutletType | MartCartOutletType) => number;
  href: string;
}

const CartCard: React.FC<ICartCardProps> = ({outletData, cartOutlet, handleRemoveOutlet, getItemsCount, href}) => {
  const {image, name, distance} = outletData;
  const {outletName, outletArea} = extractOutlet(name);

  const totalItemCount = useMemo(() => {
    return getItemsCount(cartOutlet.cartOutlet);
  }, [cartOutlet]);

  return (
    <div className="relative my-4 paddingHorizontal w-full flex flex-row justify-between items-center cursor-pointer">
      <Link href={`${href}${cartOutlet.outletFriendlyPath}/cart?fromCart=true`}>
        <div className="flex items-center flex-1">
          <div className="flex-shrink-0 flex justify-center items-center rounded overflow-hidden w-24 mr-4">
            <img src={image ?? '/image-placeholder.jpg'} alt={name} className={`object-cover rounded-xl ${image ? 'w-full h-full' : ''}`} />
          </div>

          <div>
            <p className="poppins-semibold">{outletName}</p>
            {!!outletArea && <p className="poppins-semibold text-primary font-sm">{outletArea}</p>}
            <div className="flex mt-1 items-center gap-2">
              <p className="poppins-medium font-sm">
                {totalItemCount} item{totalItemCount > 1 ? 's' : ''}
              </p>
              {distance !== null && (
                <div className="flex items-center">
                  <svg width="10" height="14" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6 0C9.3135 0 12 2.6865 12 6C12 7.30475 11.5722 8.502 10.8612 9.4825C10.8485 9.506 10.8465 9.53225 10.832 9.55475L6.832 15.5548C6.6465 15.833 6.334 16 6 16C5.666 16 5.3535 15.833 5.168 15.5548L1.168 9.55475C1.15325 9.53225 1.1515 9.506 1.13875 9.4825C0.42775 8.502 0 7.30475 0 6C0 2.6865 2.6865 0 6 0ZM6 8C7.1045 8 8 7.1045 8 6C8 4.8955 7.1045 4 6 4C4.8955 4 4 4.8955 4 6C4 7.1045 4.8955 8 6 8Z"
                      fill="#b7b7b7"
                    />
                  </svg>
                  <p className="poppins-regular font-sm ml-2">{distance.toFixed(2)} km</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
      <div className="flex-shrink-0 p-2.5 ml-3 rounded-full cursor-pointer" onClick={() => handleRemoveOutlet(cartOutlet)}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-error h-6 w-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </div>
    </div>
  );
};

export default CartCard;
