import CartBar from './CartBar';
import CartGroup from './CartGroup';
import CartDeliveryOptions from './CartDeliveryOptions';
import CartDeliveryServices from './CartDeliveryService';
import CartDeliveryInstruction from './CartDeliveryInstruction';
import CartStores from './Stores/CartStores';
import CartStore from './Stores/CartStore';
import CartStoreMenuItem from './Stores/CartStoreMenuItems';
import CartPrices from './CartPrices';
import CartPaymentMethods from './CartPaymentMethods';
import CartMixDiscount from './CartMixDiscount';
import CartCutlery from './CartCutlery';
import CartCheckoutButton from './CartCheckoutButton';

import PreviewPandago from './DeliveryServices/PreviewPandago';
import PreviewLalamove from './DeliveryServices/PreviewLalamove';

export default {
  Bar: CartBar,
  Group: CartGroup,
  DeliveryOptions: CartDeliveryOptions,
  DeliveryServices: CartDeliveryServices,
  DeliveryInstruction: CartDeliveryInstruction,
  PreviewPandago: PreviewPandago,
  PreviewLalamove: PreviewLalamove,
  Stores: CartStores,
  Store: CartStore,
  StoreMenuItem: CartStoreMenuItem,
  Prices: CartPrices,
  PaymentMethods: CartPaymentMethods,
  MixDiscount: CartMixDiscount,
  Cutlery: CartCutlery,
  CheckoutButton: CartCheckoutButton,
};
