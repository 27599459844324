import React from 'react';
import Link from 'next/link';
import {DeliveryOption, GetOngoingMixOrdersQuery} from '@graphql/generated/graphql';

import orderStateMapping from '@lib/helpers/order-state-mapping';
import getEtaFromDeliveryOption from '@lib/helpers/get-eta-from-delivery-option';

interface IMixOrdersOngoingItemProps {
  mixOrder: GetOngoingMixOrdersQuery['mixOrders'][0];
}

const MixOrdersOngoingItem: React.FC<IMixOrdersOngoingItemProps> = ({mixOrder}) => {
  const {id, orderCode, state, deliveryOption, deliveryBooking} = mixOrder;
  const eta = deliveryOption === DeliveryOption.Delivery && !deliveryBooking?.eta ? 'Pending' : getEtaFromDeliveryOption(mixOrder);

  return (
    <Link href={`/mix-orders/${id}`}>
      <a className="flex flex-col justify-between bg-white py-4 paddingHorizontal mx-5 rounded-2xl defaultShadow">
        <div className="flex flex-row justify-between items-center">
          <p className="poppins-semibold text-primary">Ongoing order</p>
          <div className="text-center bg-primary text-white py-0.5 px-2 rounded">
            <p className="poppins-medium font-sm">{orderStateMapping(state)}</p>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center my-2">
          <div>
            <p className="poppins-semibold">{orderCode}</p>
            <p className="subTextGray">Est. time {eta}</p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </a>
    </Link>
  );
};

export default MixOrdersOngoingItem;
