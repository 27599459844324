import React from 'react';

interface IDotProps {
  color?: string;
}

const Dot: React.FC<IDotProps> = ({color}) => {
  return <div className={`w-1 h-1 rounded-full mx-1.5 ${color ? color : 'bg-primary'}`}></div>;
};

export default Dot;
