import React, {useContext} from 'react';
import {DeliveryOption, PaymentMethod} from '@graphql/generated/graphql';
import {CartGroupContext} from './CartGroup';
import {useMixOrderStore, usePersistentStore} from 'stores';
import useCountryData from '@lib/useCountryData';

interface ICartCheckoutButtonProps {}

const CartCheckoutButton: React.FC<ICartCheckoutButtonProps> = () => {
  const {
    outletFriendlyPath,
    storesData,
    handleCheckout,
    cartMixinPromo: {isFoundMixinPromoApplied, mixinDiscount, subtotal},
  } = useContext(CartGroupContext);

  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const paymentMethod = useMixOrderStore(s => s.paymentMethod);
  const deliveryOption = usePersistentStore(s => s.deliveryOption);

  const checkedStoresCount = mixOrderInput.stores.filter(({checked}) => checked).length;
  const activeStoresCount = storesData?.stores?.filter(({active}) => active).length;
  const disabled = !checkedStoresCount || !activeStoresCount;

  const {currency} = useCountryData(mixOrderInput?.stores?.[0]?.country);
  const deliveryAmount = deliveryOption === DeliveryOption.Delivery ? mixOrderInput.deliveryFee : 0;
  const isNoFeeMethod = [PaymentMethod.Grabpay, PaymentMethod.Cash, PaymentMethod.Qr].includes(paymentMethod);
  const processingFee = isNoFeeMethod ? 0 : 1.1;
  const discountAmount = isFoundMixinPromoApplied ? 0 : mixOrderInput.discount.data.reduce((acc, value) => acc + value.amount, 0);
  const total = Math.max(subtotal + deliveryAmount + processingFee - discountAmount - (isFoundMixinPromoApplied ? mixinDiscount : 0), 0);

  return (
    <div className="fixed bottom-0 max-w-md bg-white h-20 w-full navbarShadow px-5 flex flex-row justify-between items-center">
      <div className="flex flex-col justify-start items-start">
        <p className="subTextGray">Total</p>
        <p className="poppins-bold font-2xl">
          {currency} {total.toFixed(2)}
        </p>
      </div>
      <button
        onClick={handleCheckout}
        className={`bg-primary py-3 my-2 flex-1 ml-6 rounded-full ${disabled ? 'opacity-50 cursor-default' : ''}`}
        disabled={disabled}>
        <p className="text-white poppins-semibold font-lg">CHECKOUT</p>
      </button>
    </div>
  );
};

export default CartCheckoutButton;
