import React, {useContext} from 'react';
import {useGetMenuItemsQuery} from '@graphql/generated/graphql';
import {useMixOrderStore} from 'stores';
import {CartGroupContext} from '../CartGroup';
import {Cart, Spinner} from '../..';

interface ICartStoresProps {}

const CartStores: React.FC<ICartStoresProps> = () => {
  const {outletFriendlyPath, storeData, storesData} = useContext(CartGroupContext);
  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));

  const menuItemIds = mixOrderInput.stores.flatMap(store => store.menuItems.map(({menuItemId}) => menuItemId));
  const {data: menuItemsData} = useGetMenuItemsQuery({
    variables: {ids: menuItemIds},
  });

  if (!storeData) {
    return (
      <div className="my-6">
        <p className="sectionHeaderSemibold">Food Items</p>
        <div className="py-10">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="my-6">
      <p className="sectionHeaderSemibold">Food Items</p>
      {mixOrderInput.stores.map(store => {
        const storeData = storesData?.stores?.find(({id}) => id === store.storeId);
        const discount = mixOrderInput.discount.data.find(d => d.storeFriendlyPath === storeData.friendlyPath);
        return <Cart.Store key={store.storeId} store={store} storeData={storeData} menuItemsData={menuItemsData} discount={discount} />;
      })}
    </div>
  );
};

export default CartStores;
