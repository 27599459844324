import React, {useEffect, useState} from 'react';
import Image from 'next/image';
import {parseCookies} from 'nookies';
import {DeliveryService, usePreviewPandagoQuery} from '@graphql/generated/graphql';
import {DELIVER_TO_ADDRESS, DELIVER_TO_LATITUDE, DELIVER_TO_LONGITUDE} from '@lib/helpers/helper';
import {format} from 'date-fns';
import PreviewLoader from './PreviewLoader';

interface IPreviewPandagoProps {
  outletId?: string;
  storeId?: string;
  deliveryService: DeliveryService;
  setDeliveryService: (service: DeliveryService) => void;
  setDeliveryData: (fee: number, metadata: string) => void;
}

const PreviewPandago: React.FC<IPreviewPandagoProps> = ({outletId, storeId, deliveryService, setDeliveryService, setDeliveryData}) => {
  const parsedCookies = parseCookies();
  const address = parsedCookies[DELIVER_TO_ADDRESS];
  const lat = +parsedCookies[DELIVER_TO_LATITUDE];
  const long = +parsedCookies[DELIVER_TO_LONGITUDE];

  const {data, loading} = usePreviewPandagoQuery({
    variables: {
      outletId,
      storeId,
      address,
      lat,
      long,
    },
    fetchPolicy: 'no-cache',
    skip: !address || !lat || !long,
  });

  const [eta, setEta] = useState<string>('');

  const isSelected = (serviceType: DeliveryService) => deliveryService === serviceType;

  const handleDeliveryService = (serviceType: DeliveryService, deliveryPrice: number, deliveryMetadata: string) => {
    setDeliveryService(serviceType);
    setDeliveryData(deliveryPrice, deliveryMetadata);
  };

  useEffect(() => {
    if (!deliveryEta) return;
    setEta(format(new Date(Date.parse(deliveryEta)), "hh:mm aaaaa'm'"));
  }, [loading]);

  if (!data || loading) return <PreviewLoader serviceType={DeliveryService.Pandago} />;

  const {
    previewPandago: {serviceName, serviceType, deliveryPrice, deliveryEta, deliveryMetadata, error},
  } = data;

  return (
    <div
      className={`flex h-20 bg-white py-2 px-4 m-2 rounded-lg items-center w-full shadow-sm cursor-pointer
      ${isSelected(serviceType) && 'orangeBoxBorder orangeShadow'}`}
      onClick={() => {
        !error && handleDeliveryService(serviceType, deliveryPrice, deliveryMetadata);
      }}>
      <div className="flex flex-row items-center justify-between w-full">
        <Image src="/pandago.png" width={40} height={40} className="bg-transparent" />
        <div className="flex flex-col flex-1 ml-4 items-start">
          <p className="poppins-semibold capitalize">{serviceName}</p>
          {error && <p className="poppins-medium text-error text-xs line-clamp-2 break-words w-full sm:w-24">{error}</p>}
          {deliveryPrice > 0 && <p className="poppins-medium mt-0.5">RM {deliveryPrice.toFixed(2)}</p>}
          {deliveryEta && <p className="subTextGrayRegular">ETA {eta}</p>}
        </div>
      </div>
    </div>
  );
};

export default PreviewPandago;
