import {UIEvent, useEffect, useReducer, useState, useRef, ElementRef, MutableRefObject} from 'react';
import {Spinner} from '..';
import Link from 'next/link';
import {BANNER_HEIGHT} from 'modules';

const classNames = (...classes: string[]) => {
  return classes.join(' ');
};

interface ICarouselProps {
  slides: string[];
  slidesUrls: string[];
}

const Carousel = (carouselProps: ICarouselProps) => {
  const {slides} = carouselProps;

  const [show, toggleShow] = useReducer(s => !s, false);
  const [slideIndex, setSlideIndex] = useState(0);

  const slidesRef = useRef<ElementRef<'div'>>(null);

  const handleScroll = (e: UIEvent<HTMLDivElement, globalThis.UIEvent>) => {
    const scrollLeft = (e.target as any).scrollLeft ?? 0;
    for (const idx in countToArray(slides.length)) {
      const n = +idx;
      const slideWidth = slidesRef.current.clientWidth;
      const thresholdLeft = n * slideWidth - slideWidth / 2;
      const thresholdRight = (n + 1) * slideWidth - slideWidth / 2;
      if (scrollLeft >= thresholdLeft && scrollLeft < thresholdRight) {
        setSlideIndex(n);
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(toggleShow, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!show) return;
    const interval = setInterval(() => {
      const cyclicIndex = (slideIndex + 1) % slides.length;
      const slideWidth = slidesRef.current.clientWidth;
      slidesRef.current.scrollTo({left: cyclicIndex * slideWidth});
      setSlideIndex(cyclicIndex);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [show, slideIndex, slides.length]);

  return (
    <div className={`overflow-hidden ${BANNER_HEIGHT}`}>
      <Loader visible={!show} />
      <Indicators visible={show} count={slides.length} index={slideIndex} />
      <Slides slidesRef={slidesRef} visible={show} onScroll={handleScroll} {...carouselProps} />
    </div>
  );
};

export default Carousel;

const Loader = ({visible}: {visible: boolean}) => {
  if (!visible) return null;
  return (
    <div className={`absolute left-0 right-0 top-0 flex justify-center items-center w-full ${BANNER_HEIGHT}`}>
      <Spinner />
    </div>
  );
};

interface ISlideProps extends ICarouselProps {
  slidesRef: MutableRefObject<ElementRef<'div'>>;
  visible: boolean;
  onScroll: (e: UIEvent<HTMLDivElement, globalThis.UIEvent>) => void;
}

const Slides = ({slidesRef, visible, slides, slidesUrls, onScroll}: ISlideProps) => {
  const locationOrigin = typeof window === 'undefined' ? '' : location.origin;
  return (
    <div
      ref={slidesRef}
      onScroll={onScroll}
      style={{scrollbarWidth: 'none'}}
      className={classNames(
        'relative flex overflow-x-scroll snap-x snap-mandatory max-w-md no-scrollbar transition duration-500',
        visible ? 'opacity-100' : 'opacity-0',
      )}>
      {slides.map((slide, index) => {
        const wrapperClass = 'flex-shrink-0 w-full snap-center justify-center items-center';
        const children = <img src={slide} alt={slide} className={`object-cover w-full ${BANNER_HEIGHT}`} />;
        if (slidesUrls[index]) {
          return (
            <div key={slide} className={`${wrapperClass} cursor-pointer`}>
              <Link href={slidesUrls[index].replace(locationOrigin, '')}>{children}</Link>
            </div>
          );
        }
        return (
          <div key={slide} className={wrapperClass}>
            {children}
          </div>
        );
      })}
    </div>
  );
};

const Indicators = ({visible, count, index}: {visible: boolean; count: number; index: number}) => {
  return (
    <div
      className={classNames(
        `absolute z-10 w-full max-w-md flex justify-center items-end pb-3.5 gap-x-1.5 pointer-events-none ${BANNER_HEIGHT}`,
        visible ? 'opacity-100' : 'opacity-0',
      )}>
      {countToArray(count).map((_, idx) => {
        return <div key={idx} className={`w-2.5 h-2.5 rounded-full ${idx === index ? 'bg-primary' : 'bg-gray opacity-75'}`}></div>;
      })}
    </div>
  );
};

const countToArray = (count: number) => {
  return Array.from({length: count}, (_, i) => i + 1);
};
