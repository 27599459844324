import React from 'react';
import {useRouter} from 'next/router';
import {useLoader} from 'context/loaderContext';

interface IBackBarProps {
  children?: React.ReactNode;
  alignment?: string;
  backUrl?: string;
  handleBack?: () => void;
  fallbackUrl?: string;
  backHidden?: boolean;
  noShadow?: boolean;
  isTransparent?: boolean;
  isArrowBgTransparent?: boolean;
}

const BackBar: React.FC<IBackBarProps> = ({
  children,
  alignment,
  backHidden,
  backUrl,
  handleBack,
  fallbackUrl = '/',
  noShadow,
  isTransparent,
  isArrowBgTransparent,
}) => {
  const router = useRouter();
  const {setShowLoader} = useLoader();

  const handlePress = () => {
    setShowLoader(true);
    if (backUrl) {
      router.push(backUrl);
      return;
    }
    const canGoBack = window.history.state && window.history.state.idx > 0;
    if (canGoBack) {
      router.back();
    } else {
      router.replace(fallbackUrl);
    }
  };

  return (
    <section>
      <div
        className={`max-w-md flex flex-row items-center h-14 px-2 z-10 ${noShadow ? '' : 'shadow'} ${
          isTransparent ? 'bg-transparent absolute top-0 ' : 'bg-white relative'
        } `}
      >
        <div className={`absolute top-0 left-0 pt-5 flex flex-row ${alignment} w-full`}>{children}</div>

        {!backHidden && (
          <div
            className={`rounded-full w-7 h-7 z-10 items-center cursor-pointer bg-transparent ${isTransparent && !isArrowBgTransparent && 'bg-white'}`}
            onClick={handleBack ? handleBack : handlePress}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-5 w-5 ml-1 my-1 z-50 cursor-pointer"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </div>
        )}
      </div>
    </section>
  );
};

export default BackBar;
