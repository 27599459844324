import React, {useContext} from 'react';
import {useRouter} from 'next/router';
import {DeliveryOption, PaymentMethod, MixinPromoCategory} from '@graphql/generated/graphql';
import {useMixOrderStore, usePersistentStore} from 'stores';
import {CartGroupContext} from './CartGroup';
import useCountryData from '@lib/useCountryData';

interface ICartPricesProps {}

const CartPrices: React.FC<ICartPricesProps> = () => {
  const router = useRouter();
  const outletFriendlyPath = router.query.friendlyPath as string;

  const {
    cartMixinPromo: {storesCount, subtotal, foundMixinPromo, mixinDiscount, isFoundMixinPromoApplied},
  } = useContext(CartGroupContext);

  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const discount = mixOrderInput.discount;
  const deliveryFee = mixOrderInput.deliveryFee;
  const deliveryOption = usePersistentStore(s => s.deliveryOption);
  const paymentMethod = useMixOrderStore(s => s.paymentMethod);
  const {currency} = useCountryData(mixOrderInput?.stores?.[0]?.country);

  const deliveryAmount = deliveryOption === DeliveryOption.Delivery ? deliveryFee : 0;

  const isNoFeeMethod = [PaymentMethod.Grabpay, PaymentMethod.Cash, PaymentMethod.Qr].includes(paymentMethod);
  const processingFee = isNoFeeMethod ? 0 : 1.1;

  const showHintText = !!foundMixinPromo && !isFoundMixinPromoApplied;
  const HintText = () => {
    if (!foundMixinPromo) return null;
    if (discountAmount) return null;

    const storesDiff = foundMixinPromo.storesCount - storesCount;
    const spendLeft = Math.max(foundMixinPromo.minSpend - subtotal, 0);
    const discountHint =
      foundMixinPromo?.category === MixinPromoCategory.Flat ? `${currency} ${foundMixinPromo.amount}` : `${foundMixinPromo.amount}%`;

    if (storesDiff === 0 && spendLeft <= 0) return null;
    if (storesDiff === 0 && spendLeft > 0) {
      return (
        <p className="poppins-medium font-sm mt-6">
          Spend {currency} <span className="text-primary">{spendLeft.toFixed(2)}</span> more to get{' '}
          <span className="text-primary">{discountHint}</span> discount.
        </p>
      );
    }
    return (
      <p className="poppins-medium font-sm mt-6">
        Add <span className="text-primary">{storesDiff}</span> more store(s) and spend{' '}
        <span className="text-primary">
          {currency} {spendLeft.toFixed(2)}
        </span>{' '}
        more to get <span className="text-primary">{discountHint}</span> discount.
      </p>
    );
  };

  const discountAmount = isFoundMixinPromoApplied ? 0 : discount.data.reduce((acc, value) => acc + value.amount, 0);

  return (
    <section className="mt-4 mb-6">
      <p className="sectionHeaderSemibold">Prices ({currency})</p>
      <div className="flex flex-row justify-between mb-0.5 items-center">
        <p className="poppins-medium font-sm">Subtotal (Tax Incl.)</p>
        <p className="poppins-medium font-sm">{subtotal.toFixed(2)}</p>
      </div>

      {!!deliveryAmount && (
        <div className="flex flex-row justify-between mb-0.5 items-center">
          <p className="poppins-medium font-sm">Delivery fee</p>
          <p className="poppins-medium font-sm">+ {deliveryAmount.toFixed(2)}</p>
        </div>
      )}

      {!!processingFee && (
        <div className="flex flex-row justify-between mb-0.5 items-center">
          <p className="poppins-medium font-sm">Payment processing fee</p>
          <p className="poppins-medium font-sm">+ {processingFee.toFixed(2)}</p>
        </div>
      )}

      {!!discountAmount && (
        <div className="flex flex-row justify-between mb-0.5 items-center">
          <p className="text-primary poppins-medium font-sm">{discount.code.toUpperCase()}</p>
          <p className="text-primary poppins-medium font-sm">- {discountAmount.toFixed(2)}</p>
        </div>
      )}

      {isFoundMixinPromoApplied && (
        <div className="flex flex-row justify-between mb-0.5 items-center">
          <p className="text-primary poppins-medium font-sm flex-1">
            {(() => {
              const display = {
                [MixinPromoCategory.Flat]: `${currency} ${foundMixinPromo.amount}`,
                [MixinPromoCategory.Percentage]: `${foundMixinPromo.amount}%`,
              }[foundMixinPromo.category];
              return `Mix & Match (${display} capped ${currency}${foundMixinPromo.capped})`;
            })()}
          </p>
          <p className="text-primary poppins-medium font-sm">
            - {currency} {mixinDiscount.toFixed(2)}
          </p>
        </div>
      )}

      {showHintText && <HintText />}
    </section>
  );
};

export default CartPrices;
