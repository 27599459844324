import React from 'react';
import Image from 'next/image';
import {DeliveryService} from '@graphql/generated/graphql';
import {MoonLoader} from 'react-spinners';

const IMAGE_MAP: Record<DeliveryService, string> = {
  [DeliveryService.Pandago]: '/pandago.png',
  [DeliveryService.Lalamove]: '/lalamove.png',
};

interface IPreviewLoaderProps {
  serviceType: DeliveryService;
}

const PreviewLoader: React.FC<IPreviewLoaderProps> = ({serviceType}) => (
  <div className="flex h-20 bg-white py-2 px-4 m-2 rounded-lg items-center opacity-50 w-full">
    <Image src={IMAGE_MAP[serviceType]} width={60} height={40} className="bg-transparent pr-4" />
    <div className="flex flex-col gap-y-1">
      <p className="poppins-semibold capitalize mr-3">{serviceType}</p>
      <MoonLoader loading={true} size={15} speedMultiplier={0.8} />
    </div>
  </div>
);

export default PreviewLoader;
