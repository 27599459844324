import React, {useEffect, useRef, useState} from 'react';
import {parseCookies} from 'nookies';
import {useAppMetadataQuery} from '@graphql/generated/graphql';
import {useFeedback} from 'context/feedbackContext';
import {DELIVER_TO_ADDRESS} from '@lib/helpers/helper';

type MapDataType = {
  address: string;
  latitude: string;
  longitude: string;
};

interface IGoogleAddressInputProps {
  country: string;
  placeChangedCallback?: (mapData: MapDataType) => void;
}

let autoComplete: google.maps.places.Autocomplete;

const GoogleAddressInput: React.FC<IGoogleAddressInputProps> = ({country, placeChangedCallback}) => {
  const autoCompleteRef = useRef(null);
  const parsedCookies = parseCookies();
  const {enqueueFeedback} = useFeedback();
  const [addressQuery, setAddressQuery] = useState<string>(parsedCookies[DELIVER_TO_ADDRESS]);

  const {data} = useAppMetadataQuery();
  const operatingCountries = data?.app?.operatingCountries?.map(({country}) => country.toLowerCase());

  useEffect(() => {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
    autoComplete.setFields(['name', 'formatted_address', 'geometry.location']);
    autoComplete.addListener('place_changed', () => {
      const addressObject = autoComplete.getPlace();
      const {name, formatted_address, geometry} = addressObject;

      if (!geometry) {
        enqueueFeedback('Invalid address, please select from dropdown', true);
        return;
      }

      const {
        location: {lat, lng},
      } = geometry;

      const fullAddress = `${name}, ${formatted_address}`;
      setAddressQuery(fullAddress);
      placeChangedCallback?.({
        address: fullAddress,
        latitude: lat().toString(),
        longitude: lng().toString(),
      });
    });
  }, []);

  useEffect(() => {
    if (country) {
      autoComplete.setComponentRestrictions({country: country.toLowerCase()});
    } else if (operatingCountries) {
      autoComplete.setComponentRestrictions({country: operatingCountries});
    }
  }, [country, operatingCountries]);

  return (
    <div className="sm:col-span-4">
      <div className="relative mt-1">
        {MAGNIFYING_GLASS_SVG}
        <input
          id="address"
          name="address"
          type="text"
          ref={autoCompleteRef}
          value={addressQuery}
          onFocus={() => setAddressQuery('')}
          onBlur={() => setAddressQuery(parsedCookies[DELIVER_TO_ADDRESS])}
          onChange={event => setAddressQuery(event.target.value)}
          required
          className="w-full bg-white poppins-regular placeholder-lightGray text-sm rounded py-2 pl-9 border-none shadow focus:border-none truncate focus:outline-none focus:ring-0"
        />
      </div>
    </div>
  );
};

export default GoogleAddressInput;

const MAGNIFYING_GLASS_SVG = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-3.5 w-3.5 absolute left-3 top-1/2 transform -translate-y-1/2">
    <path
      d="M13.7955 13.8111L19 19M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
      stroke="#b7b7b7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
