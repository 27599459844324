import {useMemo} from 'react';
import {AppMetadataQuery, MixinPromoCategory} from '@graphql/generated/graphql';
import {useMixOrderStore} from 'stores';

type MixinPromosType = AppMetadataQuery['app']['mixinPromo'];

type InputType = {
  outletId: string;
  outletFriendlyPath: string;
  mixinPromo: MixinPromosType;
};

export type OutputType = {
  storesCount: number;
  subtotal: number;
  foundMixinPromo: MixinPromosType[0];
  mixinDiscount: number;
  isFoundMixinPromoApplied: boolean;
};

const useCartMixinPromo = ({outletId, outletFriendlyPath, mixinPromo}: InputType): OutputType => {
  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));

  const storesCount = mixOrderInput.stores.filter(({checked}) => checked).length;
  const subtotal = mixOrderInput.stores
    .filter(({checked}) => checked)
    .reduce((acc, store) => {
      return acc + store.menuItems.reduce((acc, item) => acc + item.subtotal * item.count, 0);
    }, 0);

  const foundMixinPromo = useMemo(() => {
    if (!outletId) return null;
    return mixinPromo?.find(({outletIds}) => outletIds.includes(outletId));
  }, [outletId, mixinPromo, storesCount]);

  const mixinDiscount = useMemo(() => {
    if (foundMixinPromo?.category === MixinPromoCategory.Flat) {
      return Math.min(subtotal, foundMixinPromo.amount);
    }
    if (foundMixinPromo?.category === MixinPromoCategory.Percentage) {
      return Math.min(foundMixinPromo.capped, (subtotal * foundMixinPromo.amount) / 100.0);
    }
    return 0;
  }, [foundMixinPromo, subtotal]);

  const isFoundMixinPromoApplied = useMemo(() => {
    if (!foundMixinPromo) return false;
    if (storesCount < foundMixinPromo.storesCount) return false;
    if (subtotal < foundMixinPromo.minSpend) return false;
    return true;
  }, [foundMixinPromo, storesCount, subtotal]);

  return {storesCount, subtotal, foundMixinPromo, mixinDiscount, isFoundMixinPromoApplied};
};

export default useCartMixinPromo;
