import React from 'react';
import {OutletStoreMenuItemPageQuery} from '@graphql/generated/graphql';
import useCountryData from '@lib/useCountryData';

interface IMenuItemHeaderProps {
  country: string;
  menuItem: OutletStoreMenuItemPageQuery['menuItem'];
}

const MenuItemHeader: React.FC<IMenuItemHeaderProps> = ({country, menuItem: {name, description, withPromo, priceWithTax, priceWithPromo}}) => {
  const {currency} = useCountryData(country);
  return (
    <header className="bg-white paddingHorizontal py-5 defaultShadow">
      <div className="flex flex-row justify-between items-start">
        <div className="flex-1">
          <p className="poppins-semibold font-lg">{name}</p>
        </div>
        <div className="flex flex-col justify-between items-end mb-2.5">
          {withPromo && (
            <p className="text-primary poppins-semibold font-lg">
              {currency} {priceWithPromo.toFixed(2)}
            </p>
          )}
          <p className={`poppins-semibold font-lg ${withPromo && 'line-through subTextGray'}`}>
            {currency} {priceWithTax.toFixed(2)}
          </p>
        </div>
      </div>
      {!!description?.trim()?.length && <p className="subTextGray">{description}</p>}
    </header>
  );
};

export default React.memo(MenuItemHeader);
