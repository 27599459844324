import React, {useMemo} from 'react';
import {useRouter} from 'next/router';
import {useMixOrderStore} from 'stores';
import {getOutletItemsCount, useCountryData} from '@lib/index';

interface ICartBarProps {}

const CartBar: React.FC<ICartBarProps> = () => {
  const router = useRouter();
  const outletFriendlyPath = router.query.friendlyPath as string;
  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const {currency} = useCountryData(mixOrderInput?.stores?.[0]?.country);

  const handlePress = () => {
    router.push(`/outlets/${outletFriendlyPath}/cart`);
  };

  const totalItemCount = useMemo(() => {
    return getOutletItemsCount(mixOrderInput);
  }, [mixOrderInput]);

  const subtotal = useMemo(() => {
    if (!mixOrderInput?.stores) return 0;
    return mixOrderInput.stores.reduce((acc, store) => {
      return acc + store.menuItems.reduce((acc, item) => acc + item.subtotal * item.count, 0);
    }, 0);
  }, [mixOrderInput]);

  return (
    <section>
      <div className="fixed bottom-0 max-w-md bg-white h-16 w-full navbarShadow px-5">
        <button
          className="bg-black py-3 px-4 my-2 w-full rounded-full cursor-pointer flex flex-row items-center justify-center"
          onClick={handlePress}>
          <div className="flex flex-row justify-start flex-1">
            <p className="poppins-semibold text-white">View Cart</p>
            <div className="flex items-center justify-center rounded-full w-6 h-6 border border-white ml-3">
              <p className="poppins-regular font-sm text-white mr-0.5">{totalItemCount}</p>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <p className="poppins-semibold text-primary">
              {currency} {subtotal.toFixed(2)}
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#FF9906" className="h-4 w-4 mb-0.5 ml-0.5">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </button>
      </div>
    </section>
  );
};

export default CartBar;
