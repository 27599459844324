import React, {useEffect} from 'react';
import {AvailableStatus, OutletStoreMenuItemPageQuery} from '@graphql/generated/graphql';
import {CartStoreMenuOptionType} from 'stores';
import MenuItemOptionsItem from './MenuItemOptionsItem';

export type SelectItemType = {
  menuOption: OutletStoreMenuItemPageQuery['menuItem']['menuOptions'][0];
  itemOption: OutletStoreMenuItemPageQuery['menuItem']['menuOptions'][0]['itemOptions'][0];
};

interface IMenuItemOptionsProps {
  menuOptions: OutletStoreMenuItemPageQuery['menuItem']['menuOptions'];
  selectedItems: CartStoreMenuOptionType[];
  handleSelectItem: (menuOption: SelectItemType['menuOption'], itemOption: SelectItemType['itemOption']) => void;
}

const MenuItemOptions: React.FC<IMenuItemOptionsProps> = ({menuOptions, selectedItems, handleSelectItem}) => {
  const isSelected = (optionId: string, itemId: string) => {
    return !!selectedItems.find(({menuOptionId, itemOptions}) => {
      const itemOptionIds = itemOptions.map(({itemOptionId}) => itemOptionId);
      return optionId === menuOptionId && itemOptionIds.includes(itemId);
    });
  };

  useEffect(() => {
    const autoSelectCompulsoryOptionItems = () => {
      for (let option of menuOptions) {
        const {min, max, itemOptions} = option;
        for (let item of itemOptions) {
          const isCompulsory = +min === +max && +min === itemOptions.length;
          if (isCompulsory && !isSelected(option.id, item.id)) {
            handleSelectItem(option, item);
          }
        }
      }
    };
    autoSelectCompulsoryOptionItems();
  }, [menuOptions, isSelected, handleSelectItem]);

  return (
    <section>
      {menuOptions.map(menuOption => {
        const {id: menuOptionId, name: menuOptionName, min, max, itemOptions, availableStatus: optionAvailableStatus} = menuOption;
        const isOptional = +min === 0;
        const prefix = isOptional ? 'Optional' : `Pick ${min}`;
        const suffix = min === max ? '' : isOptional ? `(Max ${max})` : `- ${max}`;
        const choiceText = `${prefix} ${suffix}`;

        return (
          <div key={menuOptionId} className="pt-6 pb-3 paddingHorizontal">
            <p className="sectionHeaderBold">{menuOptionName}</p>
            <div className="flex justify-between items-center">
              <p className="subTextGray">{choiceText}</p>
            </div>
            {itemOptions.map(itemOption => {
              const {id: itemOptionId, availableStatus: itemOptionAvailableStatus} = itemOption;
              const unavailable = optionAvailableStatus === AvailableStatus.Unavailable || itemOptionAvailableStatus === AvailableStatus.Unavailable;
              const selected = isSelected(menuOptionId, itemOptionId);
              return (
                <MenuItemOptionsItem
                  key={`${menuOptionId}-${itemOptionId}`}
                  menuOption={menuOption}
                  itemOption={itemOption}
                  handleSelect={handleSelectItem}
                  selected={selected}
                  unavailable={unavailable}
                />
              );
            })}
          </div>
        );
      })}
    </section>
  );
};

export default MenuItemOptions;
