import {GetSpendingQuery} from '@graphql/generated/graphql';
import React from 'react';

interface ISavingsChartProps {
  isDense?: boolean;
  spendings: GetSpendingQuery['spendingTracker'];
}

const SavingsChart: React.FC<ISavingsChartProps> = ({isDense, spendings: {spendActual, spendSaved}}) => {
  return (
    <>
      <div className={`bg-white orangeRoundBorder orangeShadow rounded-2xl flex flex-col ${isDense ? 'gap-2 p-4' : 'gap-5 p-6'}`}>
        <div className="flex flex-row justify-between items-center">
          <p className="poppins-medium font-sm">Current spendings:</p>
          <p className="poppins-medium font-sm">RM {spendActual}</p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <p className="poppins-medium">You've saved:</p>
          <p className="poppins-semibold text-primary">RM {spendSaved}</p>
        </div>
      </div>
    </>
  );
};

export default React.memo(SavingsChart);
