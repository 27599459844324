import React, {useEffect, useState} from 'react';

import {useGetOngoingMixOrdersQuery} from '@graphql/generated/graphql';
import {ONGOING_ORDERS_STATES} from '@lib/helpers/helper';

import MixOrdersOngoingItem from './MixOrdersOngoingItem';
import BackBar from '@components/Layout/BackBar';

interface IMixOrdersOngoingProps {}

const MixOrdersOngoing: React.FC<IMixOrdersOngoingProps> = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const {data, stopPolling} = useGetOngoingMixOrdersQuery({
    variables: {
      states: ONGOING_ORDERS_STATES,
      limit: 20,
      offset: 0,
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  useEffect(() => {
    if (!data) return;
    if (!data.mixOrders.length) stopPolling();
  }, [data]);

  if (!data) return null;

  const {mixOrders} = data;
  if (!mixOrders.length) return null;

  const hasMultipleOrders = mixOrders.length > 1;

  return (
    <>
      <div className="fixed bottom-14 left-0 right-0 max-w-md mx-auto mb-4 cursor-pointer">
        {hasMultipleOrders ? (
          <div
            className="flex flex-row justify-between items-center bg-white py-4 paddingHorizontal mx-5 rounded-2xl defaultShadow"
            onClick={() => setShowModal(true)}
          >
            <div className="flex flex-col justify-between items-start">
              <p className="poppins-semibold text-primary">Ongoing orders</p>
              <p className="poppins-medium mt-1">{mixOrders.length} orders</p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        ) : (
          <MixOrdersOngoingItem key={mixOrders[0].id} mixOrder={mixOrders[0]} />
        )}
      </div>

      <div className={`fixed inset-0 max-w-md mx-auto bg-bgColor transform transition z-100 ${showModal ? 'translate-y-0' : 'translate-y-full'}`}>
        <BackBar handleBack={() => setShowModal(false)}></BackBar>
        <div className="flex flex-col gap-3 my-5">
          {mixOrders.map(mixOrder => (
            <MixOrdersOngoingItem key={mixOrder.id} mixOrder={mixOrder} />
          ))}
        </div>
      </div>
    </>
  );
};

export default MixOrdersOngoing;
