import React from 'react';
import {OutletMartMenuItemPageQuery, OutletStoreMenuItemPageQuery} from '@graphql/generated/graphql';
import {BANNER_HEIGHT} from 'modules';

interface IMenuItemBannerProps {
  menuItem: OutletStoreMenuItemPageQuery['menuItem'] | OutletMartMenuItemPageQuery['menuItem'];
  onClose: () => void;
}

const MenuItemBanner: React.FC<IMenuItemBannerProps> = ({menuItem: {name, image}, onClose}) => {
  return (
    <section>
      <div className={`${BANNER_HEIGHT}`}>
        <div className="fixed bg-white rounded-full z-10 w-7 h-7 mt-4 ml-2 flex justify-center items-center cursor-pointer" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5 my-1 z-50 cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </div>
        <img src={image ?? '/image-placeholder.jpg'} alt={name} className="w-full h-full object-cover" />
      </div>
    </section>
  );
};

export default MenuItemBanner;
